import React, { useCallback, useMemo } from "react";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { useClientSegmentOptions } from "jobber/campaigns/views/SelectClientSegmentPage/hooks/useClientSegmentOptions";
import type { Segment } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { SelectableSegments } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/components/SelectableSegments/SelectableSegments";
import { useClientSegmentSideDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/hooks/useClientSegmentSideDrawer/useClientSegmentSideDrawer";
import { messages } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/messages";
import { ClientSegmentDrawer } from "jobber/campaigns/views/SelectClientSegmentPage/components/ClientSegmentInternals/components/ClientSegmentDrawer/ClientSegmentDrawer";

interface ClientSegmentInternalsProps {
  loading: boolean;
}

export function ClientSegmentInternals({
  loading,
}: ClientSegmentInternalsProps) {
  const { formatMessage } = useIntl();

  const {
    campaignSegment: {
      selectedSegmentType,
      setSelectedSegmentType,
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    },
    campaignContent: { templateType },
  } = useCampaignWizardContext();

  const {
    openSideDrawer,
    closeSideDrawer,
    isOpen,
    segmentName,
    additionalCriteria,
    segmentCriteria,
    updateAdditionalCriteria,
  } = useClientSegmentSideDrawer();

  const segmentCriterias = useMemo(() => {
    return {
      allClientsSegmentCriteria,
      pastClientsSegmentCriteria,
      upcomingClientsSegmentCriteria,
    };
  }, [
    allClientsSegmentCriteria,
    pastClientsSegmentCriteria,
    upcomingClientsSegmentCriteria,
  ]);

  //TODO: JOB-108466 - Remove the clientSegmentData dependency for the segmentOptions. This will not be needed for the UX Improvements
  const segmentOptions = useClientSegmentOptions({
    clientsCriteriaInput: segmentCriterias,
    clientSegments: {
      pastClients: { total: 0 },
      allClients: { total: 0 },
      upcomingClients: { total: 0 },
    },
    selectedSegmentType,
    templateType,
  });

  const updateSelectedOption = useCallback(
    (value: Segment) => {
      setSelectedSegmentType(value, true);
    },
    [setSelectedSegmentType],
  );

  function openSideDrawerFn() {
    const heading =
      segmentOptions.find(card => card.type === selectedSegmentType)?.heading ||
      "";
    openSideDrawer(heading);
  }

  return (
    <Content spacing="large">
      <Text>{formatMessage(messages.pageSubHeading)}</Text>
      <SelectableSegments
        segmentOptions={segmentOptions}
        value={selectedSegmentType}
        name="ClientSegment"
        loading={loading}
        onChange={updateSelectedOption}
        criteria={{
          additionalCriteria: additionalCriteria,
          updateAdditionalCriteria: updateAdditionalCriteria,
          baseCriteria: segmentCriteria,
        }}
        openSideDrawer={openSideDrawerFn}
      />
      <ClientSegmentDrawer
        isOpen={isOpen}
        closeSideDrawer={closeSideDrawer}
        selectedSegment={selectedSegmentType}
        segmentName={segmentName}
      />
    </Content>
  );
}
