import React from "react";
import { gql } from "@apollo/client";
import { useIntl } from "react-intl";
import type { ExpiringJobsDataFragment } from "~/utilities/API/graphql";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";

export const ExpiringJobsData = gql`
  fragment ExpiringJobsData on ExpiringJobs {
    jobs {
      nodes {
        id
      }
    }
  }
`;
export interface ExpiringJobsProps {
  readonly expiringJobs?: ExpiringJobsDataFragment;
  trackEvents: boolean;
}

export function ExpiringJobsInsight({
  expiringJobs,
  trackEvents,
}: ExpiringJobsProps) {
  const [expiringJobsPath] = useUrls("expiringJobsPath");

  const { formatMessage } = useIntl();
  const expiringJobsTotal = expiringJobs?.jobs.nodes.length;

  return (
    <InsightCard
      title={formatMessage(messages.expiringJobs)}
      description={formatMessage(messages.expiringJobsDescription, {
        expiringJobsTotal,
      })}
      actionLabel={formatMessage(messages.viewJobs)}
      actionUrl={expiringJobsPath}
      hideEmptyCard={expiringJobs?.jobs.nodes.length === 0}
      trackEvents={trackEvents}
    />
  );
}
