import React from "react";
import { gql } from "@apollo/client";
import { useIntl } from "react-intl";
import type { ClientReengagementDataFragment } from "~/utilities/API/graphql";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";

export const ClientReengagementData = gql`
  fragment ClientReengagementData on ClientReengagement {
    clientCount
  }
`;

export interface ClientReengagementProps {
  readonly clientReengagement?: ClientReengagementDataFragment;
  trackEvents: boolean;
}

export function ClientReengagementInsight({
  clientReengagement,
  trackEvents,
}: ClientReengagementProps) {
  const [clientReengagementReportPath] = useUrls(
    "clientReengagementReportPath",
  );

  const { formatMessage } = useIntl();
  const clientCount = clientReengagement?.clientCount;

  return (
    <InsightCard
      title={formatMessage(messages.reEngageClients)}
      description={formatMessage(messages.reEngageClientsDescription, {
        clientCount,
      })}
      actionLabel={formatMessage(messages.viewReEngagementWork)}
      actionUrl={clientReengagementReportPath}
      hideEmptyCard={clientReengagement?.clientCount === 0}
      trackEvents={trackEvents}
    />
  );
}
