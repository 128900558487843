import React, {
  type PropsWithChildren,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "@jobber/components/Button";
import { Popover } from "@jobber/components/Popover";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import {
  CTAMarkConverted,
  CTAMarkSeen,
} from "jobber/bridges/callToActionBridge";
import { useCtas } from "~/utilities/contexts/internal/useCtas";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import type { CtaName } from "~/utilities/contexts/internal/CallToActionContext";

export interface GetItNowButtonProps {
  debitCardLinked: boolean;
  withdrawalAllowed: boolean;
  debitCardIsValid: boolean;
  getItNowButtonOnClick(): void;
}

export function GetItNowButton(props: GetItNowButtonProps) {
  const {
    getItNowButtonOnClick,
    debitCardLinked,
    debitCardIsValid,
    withdrawalAllowed,
  } = props;

  const linkedButNotAllowed = debitCardLinked && !withdrawalAllowed;

  const popoverDivRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="js-get-it-now-button" ref={popoverDivRef}>
        <Button
          type="secondary"
          size="small"
          label="Get it now"
          onClick={getItNowButtonOnClick}
          disabled={linkedButNotAllowed}
        />
      </div>
      {linkedButNotAllowed ? (
        debitCardIsValid ? (
          <WithdrawlDisabledTooltip />
        ) : (
          <DebitCardInvalidTooltip />
        )
      ) : (
        <PromoPopover targetRef={popoverDivRef} />
      )}
    </>
  );
}

interface PromoPopoverProps {
  targetRef: RefObject<HTMLDivElement>;
}

function PromoPopover({ targetRef }: PromoPopoverProps) {
  const ctaName: CtaName = "instant_payouts_eligible_prompt";
  const [{ hasDismissed: hasCtaDismissed }] = useCtas(ctaName);
  const [showPopover, setShowPopover] = useState(!hasCtaDismissed);
  const [learnMoreUrl] = useUrls("docsInstantPayoutsUrl");

  useEffect(() => {
    if (showPopover) {
      CTAMarkSeen(ctaName);
    }
  }, [showPopover]);

  const onDismiss = () => {
    CTAMarkConverted(ctaName);
    setShowPopover(false);
  };

  return (
    <Popover
      attachTo={targetRef}
      open={showPopover}
      onRequestClose={onDismiss}
      preferredPlacement="top"
    >
      <Content>
        <section className="u-marginBottomSmallest">
          <Text>
            Congrats! You&apos;re eligible to do instant payouts! You can
            transfer to your bank account 24 hours a day, 7 days a week.
          </Text>
        </section>
        <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
        <section className="u-marginTopSmallest">
          <Button
            variation="learning"
            size="small"
            label="Got it"
            onClick={onDismiss}
          />
        </section>
      </Content>
    </Popover>
  );
}

function WithdrawlDisabledTooltip() {
  return <GetItNowTooltip>You can not withdraw less than $50</GetItNowTooltip>;
}

function DebitCardInvalidTooltip() {
  return (
    <GetItNowTooltip>
      Hold tight, it will take 48 hours after you add your card before you can
      make your first instant payout.
    </GetItNowTooltip>
  );
}

function GetItNowTooltip(props: PropsWithChildren) {
  const { children } = props;
  const [learnMoreUrl] = useUrls("docsInstantPayoutsUrl");
  const isReactReady = useReady();

  return (
    <>
      {isReactReady && (
        <shared-tooltip
          bind={"hover"}
          target=".js-get-it-now-button"
          direction="above"
        >
          <section>{children}</section>
          <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
            Learn more
          </a>
        </shared-tooltip>
      )}
    </>
  );
}

function useReady() {
  const [isReady, setReady] = useState(false);
  useEffect(() => setReady(true), []);

  return isReady;
}
