import React from "react";
import classnames from "classnames";
import { Text } from "@jobber/components/Text";
import { InlineLabel } from "@jobber/components";
import { useIntl } from "react-intl";
import {
  ExpiryStatus,
  StoredPaymentMethodType,
} from "~/bunker/payments_react/clientHubJobberPayments/components/creditCard/components/VaultedCards/utils/CreditCardUIHelpers";
import { CardBrand } from "legacy/components/CardBrand/CardBrand";
import type { StoredPaymentMethodInterface } from "./types";
import styles from "./StoredCardListItem.module.css";
import { messages } from "../../../../../../../../jobber/invoices/components/cardOnFileClientModal/messages";

export function StoredCardListItem(props: StoredPaymentMethodInterface) {
  const isBankAccount = props.type === StoredPaymentMethodType.BANK_ACCOUNT;
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      {renderIcon({
        bankName: props.bankName,
        brand: props.brand,
      })}
      {renderCardNumberMasking({
        last4Digits: props.last4,
        isDefault: props.isDefault,
        bankName: props.bankName,
        isBankAccount,
        accountName: props.accountName,
      })}
      {renderExpirySection(
        props.expiryMonth,
        props.expiryYear,
        props.expiryStatus,
      )}
    </div>
  );

  function renderIcon({
    brand,
    bankName,
  }: {
    bankName?: string;
    brand?: string;
  }) {
    return (
      <>
        {isBankAccount ? (
          <img
            alt={bankName || "Bank Account"}
            src={window.SG1_IMAGE_MANIFEST["svg/bank.svg"]}
          />
        ) : (
          <CardBrand brand={brand} />
        )}
      </>
    );
  }

  function renderCardNumberMasking({
    last4Digits = "",
    isDefault = false,
    bankName = "",
    accountName = "",
  }: {
    last4Digits?: string;
    isDefault?: boolean;
    bankName?: string;
    isBankAccount: boolean;
    accountName?: string;
  }) {
    const cardNumberTitle =
      isBankAccount && accountName
        ? `${bankName?.toUpperCase()} - ${accountName.toUpperCase()}`
        : isBankAccount
          ? bankName?.toUpperCase() || "BANK ACCOUNT"
          : props.brand?.toUpperCase() || "CARD NUMBER";

    return (
      <div className={styles.cardNumberContainer}>
        <div className={styles.cardNumberInnerContainer}>
          <Text size="small">
            <span className={styles.cardNumberTitle}>{cardNumberTitle}</span>
          </Text>
          <Text size="small">
            <span className={styles.cardNumber}>{`●●●● ${last4Digits}`}</span>
          </Text>
        </div>
        {isDefault && (
          <div>
            <InlineLabel size="base">
              <span className={styles.defaultCardBadge}>
                {formatMessage(messages.defaultLabel)}
              </span>
            </InlineLabel>
          </div>
        )}
      </div>
    );
  }

  function renderExpirySection(
    month?: number,
    year?: number,
    expiryStatus: ExpiryStatus = ExpiryStatus.DEFAULT,
  ) {
    let expiryStyle: string;

    if (expiryStatus === ExpiryStatus.EXPIRED) {
      expiryStyle = classnames(styles.expiryContainer, styles.isExpired);
    } else if (expiryStatus === ExpiryStatus.EXPIRING_SOON) {
      expiryStyle = classnames(styles.expiryContainer, styles.expiringSoon);
    } else {
      expiryStyle = classnames(styles.expiryContainer);
    }

    const renderExpiryContent = () => {
      if (!month || !year) {
        return;
      }

      return (
        <>
          <Text size="small">EXPIRY</Text>
          <Text size="small">{`${month}/${year
            .toString()
            .trim()
            .substr(2, 4)}`}</Text>
        </>
      );
    };

    return (
      <div className={expiryStyle} data-testid="expiry">
        {renderExpiryContent()}
      </div>
    );
  }
}
