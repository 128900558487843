import React from "react";
import { Content } from "@jobber/components/Content";
import { Option, Select } from "@jobber/components/Select";
import { InputText } from "@jobber/components/InputText";
import { Checkbox } from "@jobber/components/Checkbox";
import { InputGroup } from "@jobber/components/InputGroup";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import type { LineItemImageSelectorChangedEvent } from "jobber/lineItemImageSelector/components/LineItemImageSelector/LineItemImageSelector";
import { InputCurrency } from "components/InputCurrency";
import type { WorkItem } from "jobber/workItems/types";
import {
  type UpdateCostAction,
  calculateCostFields,
} from "jobber/workItems/utils";
import { withIntlProvider } from "@translations/withIntlProvider";
import { MagicInputText } from "components/MagicInputText/MagicInputText";
import { WorkItemSelfServeBookingsSettings } from "./components/WorkItemSelfServeBookingsSettings";
import styles from "./styles.module.css";
import {
  WorkItemImageSelector,
  type WorkItemImageSelectorProps,
} from "./components/WorkItemImageSelector/WorkItemImageSelector";
import { messages } from "./messages";

export interface FormProps {
  workItem: WorkItem;
  currencySymbol: string;
  onSelfServeBookingsPage?: boolean;
  quoteMarginsEnabled: boolean;
  advancedQuotingEnabled: boolean;
  selfServeBookingsEnabled: boolean;
  onChange: React.Dispatch<React.SetStateAction<WorkItem>>;
}

const fileAttachmentDeletedData: LineItemImageSelectorChangedEvent = {
  fileName: "",
  contentType: "",
  fileSize: 0,
  s3Key: "",
  thumbnail: undefined,
};

// eslint-disable-next-line max-statements
export function Form({
  workItem,
  currencySymbol,
  onSelfServeBookingsPage,
  quoteMarginsEnabled,
  advancedQuotingEnabled,
  selfServeBookingsEnabled,
  onChange,
}: FormProps) {
  const { formatMessage } = useIntl();

  const editing = workItem.id != undefined;
  //When it is impossible to create a non-visible workItem with onlineBookingsEnabled, we should remove the last check
  const showSelfServeBookings =
    !editing || workItem.visible || workItem.onlineBookingsEnabled;

  function changeWorkItemCopyAttribute<key extends keyof WorkItem>(
    attribute: key,
  ) {
    return (newValue: WorkItem[key]) => {
      handleWorkItemCopyChange(attribute, newValue);
    };
  }

  const changeWorkItemTaxable = () => (newValue: boolean) => {
    handleWorkItemCopyChange("taxable", !newValue);
  };

  const changeWorkItemVisible = () => (newValue: boolean) => {
    handleWorkItemCopyChange("visible", newValue);
    if (!newValue && workItem.onlineBookingsEnabled) {
      handleWorkItemCopyChange("onlineBookingsEnabled", false);
    }
  };

  const imageSelectorAttributesCopy: WorkItemImageSelectorProps =
    workItemImageSelectorProps(workItem);

  const warningBannerProps = {
    warnAboutSyncStatus: workItem.accountingSync?.warnAboutSyncStatus || false,
    disableSyncedFields: workItem.accountingSync?.disableSyncedFields || false,
    accountingSyncName: workItem.accountingSync?.accountingSyncName || "",
  };

  return (
    <Content>
      <WarningBanner {...warningBannerProps} />

      <div
        aria-label={formatMessage(messages.ariaModalContent)}
        className={styles.spaceChildren}
      >
        <div aria-label={formatMessage(messages.ariaCategorySelect)}>
          <Select
            name="category"
            placeholder="Item type"
            onChange={changeWorkItemCopyAttribute("category")}
            value={workItem.category}
            defaultValue={"Service"}
            disabled={workItem.accountingSync?.disableSyncedFields}
          >
            <Option value="SERVICE">
              {formatMessage(messages.serviceCategoryLabel)}
            </Option>

            <Option value="PRODUCT">
              {formatMessage(messages.productCategoryLabel)}
            </Option>
          </Select>
        </div>

        <div aria-label={formatMessage(messages.ariaItemNameInput)}>
          <InputText
            name="name"
            value={workItem.name}
            placeholder={formatMessage(messages.itemNamePlaceholder)}
            onChange={changeWorkItemCopyAttribute("name")}
            disabled={workItem.accountingSync?.disableSyncedFields}
          />
        </div>

        <div aria-label={formatMessage(messages.ariaItemDescriptionInput)}>
          <MagicInputText
            name="description"
            placeholder={formatMessage(messages.itemDescriptionPlaceholder)}
            multiline
            value={workItem.description}
            onChange={changeWorkItemCopyAttribute("description") as () => void}
            disabled={workItem.accountingSync?.disableSyncedFields}
          />
        </div>

        {quoteMarginsEnabled ? (
          <InputGroup flowDirection="horizontal">
            <div aria-label={formatMessage(messages.ariaItemCostInput)}>
              <InputCurrency
                value={workItem.internalUnitCost}
                placeholder={`${formatMessage(
                  messages.itemCostPlaceholder,
                )} (${currencySymbol})`}
                onChange={setInternalUnitCost}
                disabled={workItem.accountingSync?.disableSyncedFields}
              />
            </div>

            <div aria-label={formatMessage(messages.ariaItemMarkupInput)}>
              <InputCurrency
                value={workItem.markup}
                placeholder={formatMessage(messages.itemMarkupPlaceholder)}
                onChange={setMarkup}
                disabled={workItem.accountingSync?.disableSyncedFields}
                decimalPlaces={0}
                maximumDecimalPlaces={5}
              />
            </div>

            <div aria-label={formatMessage(messages.ariaItemUnitPriceInput)}>
              <InputCurrency
                value={workItem.defaultUnitCost}
                placeholder={`${formatMessage(
                  messages.itemUnitPricePlaceholder,
                )} (${currencySymbol})`}
                onChange={setUnitCost}
                disabled={workItem.accountingSync?.disableSyncedFields}
                maximumDecimalPlaces={2}
              />
            </div>
          </InputGroup>
        ) : (
          <InputCurrency
            value={workItem.defaultUnitCost}
            placeholder={`${formatMessage(
              messages.itemUnitPricePlaceholder,
            )} (${currencySymbol})`}
            onChange={setUnitCost}
            disabled={workItem.accountingSync?.disableSyncedFields}
          />
        )}
        {advancedQuotingEnabled && (
          <div>
            <WorkItemImageSelector
              {...imageSelectorAttributesCopy}
              onChanged={updateWorkItemFileAttachment}
              onDeleted={deleteFileAttachmentData}
            />
          </div>
        )}
        <div aria-label={formatMessage(messages.ariaItemTaxExemptInput)}>
          <Checkbox
            name="taxable"
            label={formatMessage(messages.exemptFromTaxLabel)}
            checked={!workItem.taxable}
            onChange={changeWorkItemTaxable()}
            disabled={workItem.accountingSync?.disableSyncedFields}
          />

          {editing && !onSelfServeBookingsPage && (
            <Checkbox
              label={formatMessage(messages.itemAvailableLabel)}
              checked={workItem.visible}
              onChange={changeWorkItemVisible()}
            />
          )}
        </div>

        {/* There is some data in prod already where visible is false and booking is enabled
            These people should still be able to turn off online booking in this state
            todo JOB-72505 remove || workItem.onlineBookingsEnabled check that allows showing the section */}
        {selfServeBookingsEnabled && showSelfServeBookings && (
          <WorkItemSelfServeBookingsSettings
            bookingEnabled={!!workItem.onlineBookingsEnabled}
            handleDurationChange={changeWorkItemCopyAttribute(
              "durationMinutes",
            )}
            handleBookingEnabledChange={changeWorkItemCopyAttribute(
              "onlineBookingsEnabled",
            )}
            durationMinutes={workItem?.durationMinutes}
            onSelfServeBookingsPage={onSelfServeBookingsPage}
            quantityRange={workItem.quantityRange}
            handleServiceQuantityChange={changeWorkItemCopyAttribute(
              "quantityRange",
            )}
            bookableType={workItem.bookableType}
            handleBookableTypeChange={changeWorkItemCopyAttribute(
              "bookableType",
            )}
          />
        )}
      </div>
    </Content>
  );

  function setInternalUnitCost(newValue: number) {
    updateCostFields({
      prop: "internalUnitCost",
      value: newValue,
    });
  }

  function setMarkup(newValue: number) {
    updateCostFields({
      prop: "markup",
      value: newValue,
    });
  }

  function setUnitCost(newValue: number) {
    updateCostFields({
      prop: "defaultUnitCost",
      value: newValue,
    });
  }

  function updateWorkItemFileAttachment(
    event: LineItemImageSelectorChangedEvent,
  ) {
    handleWorkItemCopyChange("fileAttachment", {
      fileName: event.fileName || "",
      contentType: event.contentType || "",
      fileSize: event.fileSize || 0,
      s3Key: event.s3Key || "",
      thumbnailUrl: event.thumbnail,
    });
  }

  function deleteFileAttachmentData() {
    updateWorkItemFileAttachment(fileAttachmentDeletedData);
  }

  function handleWorkItemCopyChange<key extends keyof WorkItem>(
    attributeName: key,
    newValue: WorkItem[key],
  ) {
    // We need this syntax for updating  state because InputFile has a bug JOB-65659 memoizing the upload functions.
    // We can't access anything that would change between renders in this function.
    // OnChange must be either memoized or a setter from useState (which is always the same function between renders)
    onChange(currentWorkItem => ({
      ...currentWorkItem,
      [attributeName]: newValue,
    }));
  }

  function updateCostFields(update: UpdateCostAction) {
    onChange(item => ({
      ...item,
      ...calculateCostFields(workItem, update),
    }));
  }
}

export const FormWrapper = withIntlProvider(Form);

interface WarningBannerProps {
  warnAboutSyncStatus: boolean;
  disableSyncedFields: boolean;
  accountingSyncName: string;
}

function WarningBanner({
  warnAboutSyncStatus,
  disableSyncedFields,
  accountingSyncName,
}: WarningBannerProps) {
  const { formatMessage } = useIntl();
  if (!warnAboutSyncStatus) {
    return <></>;
  }

  if (disableSyncedFields) {
    return (
      <Banner type="warning">
        {formatMessage(messages.bannerDisabledFieldsSyncError, {
          accountingSyncName,
        })}
      </Banner>
    );
  } else {
    return (
      <Banner type="warning">
        {formatMessage(messages.bannerOverwriteOnSyncError, {
          accountingSyncName,
        })}
      </Banner>
    );
  }
}

function workItemImageSelectorProps(
  workItem: WorkItem,
): WorkItemImageSelectorProps {
  let s3Key = "";
  let fileName = "";
  let contentType = "";
  let fileSize = 0;
  let thumbnail = "";
  if (workItem.fileAttachment) {
    s3Key = workItem.fileAttachment.s3Key;
    fileName = workItem.fileAttachment.fileName;
    contentType = workItem.fileAttachment.contentType;
    fileSize = workItem.fileAttachment.fileSize;
    thumbnail = workItem.fileAttachment.thumbnailUrl || "";
  }

  return {
    parentIsReact: true,
    thumbnailUrl: thumbnail,
    s3Key,
    fileName,
    contentType,
    fileSize,
    canUpload: true,
    uniqueId: workItem.id?.toString(),
    uploadUrl: "",
  };
}
