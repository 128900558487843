import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";
import { Page } from "@jobber/components/Page";
import { generatePath, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Checkbox } from "@jobber/components/Checkbox";
import { InputValidation } from "@jobber/components/InputValidation";
import { showToast } from "@jobber/components/Toast";
import { useCampaignCheckFromEmail } from "jobber/campaigns/hooks/useCampaignCheckFromEmail";
import { ValidateEmailModal } from "jobber/campaigns/components/ValidateEmailModal/ValidateEmailModal";
import { transformCommsCampaign } from "jobber/campaigns/views/CampaignsReviewPage/hooks/utils";
import {
  CampaignsBreadCrumb,
  breadCrumbMessages,
} from "jobber/campaigns/components/Breadcrumbs";
import { BottomBar } from "jobber/campaigns/components/Bottombar/BottomBar";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_LANDING_PAGE_PATH,
} from "jobber/campaigns/constants";
import { useCommsCampaignTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignTemplateQuery";
import {
  CampaignReviewErrorState,
  useCampaignsReviewActions,
} from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCampaignsReviewActions/useCampaignsReviewActions";
import { usePreventEditCampaign } from "jobber/campaigns/hooks/usePreventEditCampaign/usePreventEditCampaign";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import { useCustomClientSideValidation } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCustomClientSideValidation";
import { useCampaignCompanyInformation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignCompanyInformation";
import { EmailPreview } from "jobber/campaigns/components/EmailPreview";
import { messages as bottomBarMessages } from "jobber/campaigns/components/Bottombar/messages";
import { RecipientsSection } from "jobber/campaigns/views/CampaignsReviewPage/components/RecipientsSection/RecipientsSection";
import { useCommsCampaignReviewQuery } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCommsCampaignReviewQuery/useCommsCampaignReviewQuery";
import { AutomatedCampaignRecipientsSection } from "jobber/campaigns/views/CampaignsReviewPage/components/AutomatedCampaignRecipientsSection/AutomatedCampaignRecipientsSection";
import { BottomBarPurchaseFlow } from "jobber/campaigns/views/CampaignsReviewPage/components/BottomBarPurchaseFlow/BottomBarPurchaseFlow";
import { BottomBarDefaultFlow } from "jobber/campaigns/views/CampaignsReviewPage/components/BottomBarDefaultFlow/BottomBarDefaultFlow";
import { SplitNames, useSplit } from "utilities/split";
import { useEmailCampaignsPurchaseFlow } from "jobber/campaigns/hooks/useEmailCampaignsPurchaseFlow/useEmailCampaignsPurchaseFlow";
import { messages } from "./messages";
import styles from "./CampaignsReviewPage.module.css";
import { ScheduleSendModal } from "../../components/ScheduleSendModal/ScheduleSendModal";

export interface EmptyClientSegmentErrorBannerProps {
  redirectToEdit: () => void;
}

function EmptyClientSegmentErrorBanner({
  redirectToEdit,
}: EmptyClientSegmentErrorBannerProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Banner
      type={"error"}
      primaryAction={{
        label: formatMessage(messages.emptyClientSegmentErrorBannerCtaText),
        onClick: redirectToEdit,
      }}
    >
      {formatMessage(messages.emptyClientSegmentErrorBannerText)}
    </Banner>
  );
}

function CampaignSendErrorBanner({
  message,
  setErrorState,
}: {
  message: string;
  setErrorState: Dispatch<SetStateAction<CampaignReviewErrorState | null>>;
}): JSX.Element {
  return (
    <Banner type={"error"} onDismiss={() => setErrorState(null)}>
      {message}
    </Banner>
  );
}
export interface CampaignsReviewPageProps {
  campaignId: string;
  recurlyPublicKey: string;
  salesforceTrackingId: string | null;
}

export function CampaignsReviewPage(props: CampaignsReviewPageProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <CampaignsReviewPageInternal {...props} />
      </IntlProvider>
    </APIProvider>
  );
}

// eslint-disable-next-line max-statements
function CampaignsReviewPageInternal({
  campaignId,
  recurlyPublicKey,
  salesforceTrackingId,
}: CampaignsReviewPageProps): JSX.Element {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [confirmImpliedConsentPermission, setConfirmImpliedConsentPermission] =
    useState(false);

  const [showScheduleSendModal, setShowScheduleSendModal] = useState(false);
  const [scheduleSendError, setScheduleSendError] = useState<
    string | undefined
  >(undefined);

  const {
    loadingCampaign,
    campaignData,
    loadingClientSegment,
    clientSegmentData,
    refetchCampaign,
    timeoutError,
  } = useCommsCampaignReviewQuery({
    campaignId: campaignId,
    onCampaignNotFound: () => {
      history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
    },
  });

  const {
    loadingCompanyInformation,
    street1,
    city,
    province,
    postalCode,
    country,
  } = useCampaignCompanyInformation();
  if (
    !loadingCompanyInformation &&
    !(street1 && city && province && postalCode && country)
  ) {
    history.replace(generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, { campaignId }));
  }

  const { template, loading: loadingTemplate } = useCommsCampaignTemplateQuery({
    campaignId,
  });

  const { getPageStep } = useCampaignUpsellSplit();

  const { isReady: isSplitReady, getTreatmentValue } = useSplit({
    names: [SplitNames.CampaignAutomations],
  });

  const enableAutomation =
    getTreatmentValue(SplitNames.CampaignAutomations) &&
    isSplitReady &&
    campaignData?.commsCampaign?.isAutomated;

  const {
    toggleModal,
    isFromEmailValidated,
    showValidateEmailModal,
    sendValidationCode,
    checkValidationCode,
    isCheckValidationCodeDataLoading,
    error,
  } = useCampaignCheckFromEmail({ campaignData, refetchCampaign });

  const {
    totalCount,
    segmentName,
    replyToEmail,
    fromEmail,
    consentingClientsCount,
    hasClientWithoutConsentEntries,
  } = transformCommsCampaign({ campaignData, clientSegmentData });

  usePreventEditCampaign({
    campaignStatus: campaignData?.commsCampaign?.status,
  });

  const { validationErrors, checkIsFormValid } = useCustomClientSideValidation({
    validationRules: {
      confirmImpliedConsentPermission: () => {
        if (
          (hasClientWithoutConsentEntries &&
            !confirmImpliedConsentPermission) ||
          (enableAutomation && !confirmImpliedConsentPermission)
        ) {
          return formatMessage(messages.shouldShowConsentEntriesErrorMessage);
        }
      },
    },
  });

  const {
    previewEnabled,
    isMobileBilled,
    loading: previewLoading,
  } = useEmailCampaignsPurchaseFlow(campaignId, isFromEmailValidated);

  const loadingCampaignInformation = loadingCampaign || loadingTemplate;
  const loadingAll = loadingCampaignInformation || loadingClientSegment;

  const submitButtonLabel = () => {
    if (!isFromEmailValidated && !loadingCampaignInformation) {
      return formatMessage(bottomBarMessages.verifyFromEmail);
    }
    if (enableAutomation) {
      return formatMessage(messages.automatedCampaignActivateButton);
    } else {
      return formatMessage(bottomBarMessages.sendCampaign);
    }
  };

  const {
    errorState,
    setErrorState,
    redirectToEdit,
    onNext,
    validateCampaignForSending,
    onCancel,
    actionLoading,
  } = useCampaignsReviewActions({
    campaignId,
    campaign: campaignData?.commsCampaign,
    totalCount,
    toggleEmailVerificationModal: toggleModal,
    loading: loadingAll,
    checkIsFormValid,
    timeoutError,
  });

  const scheduleSend = async (date: Date) => {
    try {
      await onNext(date);
      showToast({
        message: formatMessage(messages.scheduledCampaignSuccessToast),
        variation: "success",
      });
    } catch (err) {
      switch (err.message) {
        case CampaignReviewErrorState.SCHEDULE_INVALID:
          setScheduleSendError(
            formatMessage(messages.scheduledCampaignDateTimeErrorToast),
          );
          return;
        default:
          setScheduleSendError(
            formatMessage(messages.scheduledCampaignErrorToast),
          );
          return;
      }
    }
  };

  const shouldShowConsentCheckbox =
    enableAutomation || hasClientWithoutConsentEntries;

  return (
    <div className={styles.container}>
      <CampaignsBreadCrumb
        currentStep={formatMessage(breadCrumbMessages.reviewLabel)}
        onBack={onCancel}
        loading={loadingCampaignInformation || actionLoading}
      />
      <ScheduleSendModal
        showModal={showScheduleSendModal}
        toggleModal={() => setShowScheduleSendModal(!showScheduleSendModal)}
        onSubmit={scheduleSend}
        loading={loadingCampaignInformation || actionLoading}
        error={scheduleSendError}
        onErrorDismiss={() => setScheduleSendError(undefined)}
      />
      <div className={styles.reviewPageContainer}>
        <div className={styles.editContainer}>
          <Page title={formatMessage(messages.campaignReviewPageTitle)}>
            {showValidateEmailModal && campaignId && (
              <ValidateEmailModal
                showModal={showValidateEmailModal}
                toggleModal={toggleModal}
                email={campaignData?.commsCampaign?.fromEmailAddress?.rawEmail}
                error={error}
                sendValidationCode={sendValidationCode}
                checkValidationCode={checkValidationCode}
                isCheckValidationCodeDataLoading={
                  isCheckValidationCodeDataLoading
                }
              />
            )}
            {errorState === CampaignReviewErrorState.EMPTY_CLIENT_SEGMENT &&
              !enableAutomation && (
                <EmptyClientSegmentErrorBanner
                  redirectToEdit={redirectToEdit}
                />
              )}
            {errorState === CampaignReviewErrorState.AUTHORIZATION_ERROR && (
              <CampaignSendErrorBanner
                message={formatMessage(
                  messages.campaignSendAuthorizationErrorBannerText,
                )}
                setErrorState={setErrorState}
              />
            )}
            {errorState === CampaignReviewErrorState.GENERIC_ERROR && (
              <CampaignSendErrorBanner
                message={formatMessage(messages.campaignSendErrorBannerText)}
                setErrorState={setErrorState}
              />
            )}
            {!enableAutomation && (
              <RecipientsSection
                loading={loadingAll || !isSplitReady}
                segmentName={segmentName}
                consentingClientsCount={consentingClientsCount}
                totalCount={totalCount}
                timeoutError={timeoutError}
                redirectToEdit={redirectToEdit}
              />
            )}
            {enableAutomation && (
              <AutomatedCampaignRecipientsSection
                loading={loadingCampaignInformation || !isSplitReady}
                timeoutError={timeoutError}
                redirectToEdit={redirectToEdit}
                automationRule={campaignData?.commsCampaign?.automationRule}
              />
            )}

            {shouldShowConsentCheckbox && (
              <div className={styles.confirmImpliedConsentPermissionContainer}>
                <Checkbox
                  label={formatMessage(
                    messages.campaignsConfirmImpliedConsentPermission,
                  )}
                  onChange={setConfirmImpliedConsentPermission}
                />
                {validationErrors?.confirmImpliedConsentPermission && (
                  <InputValidation
                    message={validationErrors?.confirmImpliedConsentPermission}
                  />
                )}
              </div>
            )}
            <Heading level={4}>
              {formatMessage(messages.campaignsFromEmail)}
            </Heading>
            {loadingCampaignInformation && <Glimmer width={500} />}
            {fromEmail && !loadingCampaignInformation && (
              <div className={styles.fromEmailContainer}>
                <Text> {fromEmail}</Text>
                <InlineLabel color={isFromEmailValidated ? "green" : "yellow"}>
                  {isFromEmailValidated
                    ? formatMessage(messages.fromEmailVerifiedLabel)
                    : formatMessage(messages.fromEmailNotVerifiedLabel)}
                </InlineLabel>
              </div>
            )}
            {fromEmail &&
              !loadingCampaignInformation &&
              !isFromEmailValidated && (
                <Text variation="warn">
                  {formatMessage(messages.fromEmailNotVerifiedText)}
                </Text>
              )}
            <Heading level={4}>
              {formatMessage(messages.campaignsReplyToEmailTitle)}
            </Heading>
            {loadingCampaignInformation && <Glimmer width={500} />}
            {replyToEmail && !loadingCampaignInformation && (
              <Text>{replyToEmail}</Text>
            )}
            <Heading level={4}>
              {formatMessage(messages.campaignsSubjectTitle)}
            </Heading>
            {loadingCampaignInformation && <Glimmer width={500} />}
            {template.subject && !loadingCampaignInformation && (
              <Text>{template.subject}</Text>
            )}
          </Page>
        </div>
        <div
          className={styles.previewContainer}
          data-testid={"CampaignEmailPreview"}
        >
          <EmailPreview
            header={template.header}
            subject={template.subject}
            fromEmail={fromEmail}
            body={template.body}
            buttonColor={template.buttonColor}
            buttonText={template.buttonText}
            ctaButtonEnabled={template.ctaButtonEnabled}
            showLogo={template.showLogo}
            loading={loadingTemplate}
            headerImageUrl={template.headerImage?.url}
            variables={template.variables}
            referralLink={template.referralLink}
          />
        </div>
      </div>

      <BottomBar
        onNext={onNext}
        onCancel={onCancel}
        step={getPageStep("review")}
        isFromEmailValidated={isFromEmailValidated}
        loading={loadingAll || actionLoading || previewLoading}
      >
        {!previewEnabled && (
          <BottomBarDefaultFlow
            enableAutomation={enableAutomation}
            onNext={onNext}
            setShowScheduleSendModal={setShowScheduleSendModal}
            validateCampaignForSending={validateCampaignForSending}
            loadingAll={loadingAll}
            submitButtonLabel={submitButtonLabel()}
          />
        )}
        {previewEnabled && (
          <BottomBarPurchaseFlow
            enableAutomation={enableAutomation}
            isMobileBilled={isMobileBilled}
            recurlyPublicKey={recurlyPublicKey}
            salesforceTrackingId={salesforceTrackingId}
            onNext={onNext}
            checkIsFormValid={checkIsFormValid}
            setShowScheduleSendModal={setShowScheduleSendModal}
            loadingAll={loadingAll}
          />
        )}
      </BottomBar>
    </div>
  );
}
