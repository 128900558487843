import React, { useCallback, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import type { MessageDescriptor } from "react-intl";
import { useIntl } from "react-intl";
import { Avatar } from "@jobber/components/Avatar";
import { Chip, Chips } from "@jobber/components/Chips";
import { showToast } from "@jobber/components/Toast";
import { InputValidation } from "@jobber/components/InputValidation";
import { ConfigurationSetting } from "jobber/settings/selfServeBookings/views/SettingsPage/components/ConfigurationSetting/ConfigurationSetting";
import type { UserFragment } from "~/utilities/API/graphql";
import { useUpdateSchedule } from "jobber/settings/selfServeBookings/views/SettingsPage/hooks/useUpdateSchedule";
import { messages } from "./messages";

const DEBOUNCE_DELAY = 2000;

interface ValidationProps {
  readonly messages: MessageDescriptor[];
}

function Validation({ messages: validationMessages }: ValidationProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      {validationMessages.map((message, index) => (
        <InputValidation
          key={`bookable_team_members:error:${index}`}
          message={formatMessage(message)}
        />
      ))}
    </>
  );
}

interface InputUsersProps {
  readonly value: UserFragment[];
  readonly onChange: (users: string[]) => void;
  readonly options: UserFragment[];
}

function InputUsers({
  value,
  onChange,
  options,
}: InputUsersProps): JSX.Element {
  const selected = useMemo(() => value.map(u => u.id), [value]);

  return (
    <Chips type="dismissible" selected={selected} onChange={onChange}>
      {options.map(member => (
        <Chip
          key={member.name.full}
          label={member.name.full}
          value={member.id}
          prefix={
            <Avatar
              size="base"
              initials={member.avatar.initials}
              imageUrl={member.avatar.image?.photoUrl}
            />
          }
        />
      ))}
    </Chips>
  );
}

export interface BookableTeamMembersConfigurationProps {
  bookableUsers: UserFragment[];
  debounceDelay?: number;
  users: UserFragment[];
}

export function BookableTeamMembersConfiguration({
  bookableUsers,
  /* istanbul ignore next -- @preserve */
  debounceDelay = DEBOUNCE_DELAY,
  users,
}: BookableTeamMembersConfigurationProps) {
  const { formatMessage } = useIntl();
  const initalValidations = useMemo(() => {
    if (bookableUsers.length < 1) {
      return [messages.noTeamMembers];
    }

    return [];
  }, [bookableUsers]);
  const [bookable, setBookable] = useState<UserFragment[]>(bookableUsers);
  const [validationMessages, setValidationMessages] =
    useState<MessageDescriptor[]>(initalValidations);

  const url = useMemo(() => {
    return (
      <a href="/manage_team" rel="noopener noreferrer" target="_blank">
        {formatMessage(messages.manageTeam)}
      </a>
    );
  }, [formatMessage]);

  const { editSelfServeScheduleSettings } = useUpdateSchedule();

  const updateSettings = useCallback(
    (userIds: string[]) => {
      editSelfServeScheduleSettings({ bookableUsers: userIds })
        .then(() => {
          showToast({
            message: formatMessage(messages.settingSaved),
          });
        })
        .catch(() => {
          setValidationMessages([messages.errorMessage]);
        });
    },
    [editSelfServeScheduleSettings, formatMessage],
  );

  const debouncedUpdate = useRef(
    debounce(updateSettings, debounceDelay),
  ).current;

  const onChange = useCallback(
    (userIds: string[]) => {
      const selected = users.filter(u => userIds.includes(u.id));

      if (selected.length) {
        setValidationMessages([]);
        setBookable(selected);

        return debouncedUpdate(userIds);
      }

      // no selected users
      if (validationMessages.length === 0) {
        setValidationMessages(previous => {
          return [...previous, messages.noTeamMembers];
        });
      }
    },
    [debouncedUpdate, users, validationMessages],
  );

  return (
    <ConfigurationSetting
      title={formatMessage(messages.configurationLabel)}
      description={formatMessage(messages.configurationDescription, { url })}
    >
      <InputUsers value={bookable} onChange={onChange} options={users} />
      <Validation messages={validationMessages} />
    </ConfigurationSetting>
  );
}
