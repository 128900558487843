import React from "react";
import { useIntl } from "react-intl";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";

export interface CustomizeBrandingProps {
  trackEvents: boolean;
  onButtonClick(): void;
}

export function CustomizeBrandingInsight({
  trackEvents,
  onButtonClick,
}: CustomizeBrandingProps) {
  const [editWorkConfigurationPath] = useUrls("editWorkConfigurationPath");

  const { formatMessage } = useIntl();

  return (
    <InsightCard
      title={formatMessage(messages.customizeBranding)}
      description={formatMessage(messages.customizeBrandingDescription)}
      actionLabel={formatMessage(messages.customizeBrandingAction)}
      actionUrl={editWorkConfigurationPath}
      hideEmptyCard={false}
      trackEvents={trackEvents}
      onButtonClick={onButtonClick}
    />
  );
}
