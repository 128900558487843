import React from "react";
import { gql } from "@apollo/client";
import { useIntl } from "react-intl";
import type { OverdueItemsTotalDataFragment } from "~/utilities/API/graphql";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";

export const OverdueItemsTotalData = gql`
  fragment OverdueItemsTotalData on OverdueItems {
    total
  }
`;
export interface ScheduledItemsOverdueProps {
  overdueItems?: OverdueItemsTotalDataFragment;
  trackEvents: boolean;
}

export function ScheduledItemsOverdueInsight({
  overdueItems,
  trackEvents,
}: ScheduledItemsOverdueProps) {
  const [calendarListPath] = useUrls("calendarListPath");
  const { formatMessage } = useIntl();
  const overdueItemsTotal = overdueItems?.total;

  return (
    <InsightCard
      title={formatMessage(messages.overdueItems)}
      description={formatMessage(messages.overdueItemsDescription, {
        overdueItemsTotal,
      })}
      actionLabel={formatMessage(messages.viewCalendar)}
      actionUrl={calendarListPath}
      hideEmptyCard={overdueItems?.total === 0}
      trackEvents={trackEvents}
    />
  );
}
