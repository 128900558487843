import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { showToast } from "@jobber/components";
import { Grid } from "@jobber/components/Grid";
import { Page } from "@jobber/components/Page";
import { type MutableRefObject, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import {
  EditBillingInfo,
  type EditBillingInfoRef,
} from "jobber/billing/components/EditBillingInfo";
import type {
  FieldErrorState,
  FormErrorState,
} from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PurchaseFormErrors } from "jobber/billing/components/PurchaseFormErrors";
import { PurchaseFormContextProvider } from "jobber/billing/hooks/PurchaseFormContext";
import { useStoredUpdateResult } from "jobber/billing/hooks/useStoredUpdateResult";
import {
  CONFIRM_SUBSCRIPTION_INTERACTION,
  type TrackingDetails,
  trackAutoAddedAddon,
  trackFailedSubmitRecurlyForm,
  trackInteractedWithButton,
  trackInteractedWithInput,
} from "jobber/billing/utils/trackInteractions";
import { useViewport } from "jobber/hooks/useViewport";
import { csrfToken } from "utilities/csrfToken";
import { formatCurrency } from "utilities/formatCurrency";
import { ACCOUNT_PLAN_INFO } from "~/jobber/plans/Plans.graphql";
import type { AccountPlanInfoType } from "~/jobber/plans/types";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import type {
  BillingCycleName,
  MutationErrors,
  SubscriptionUpdateMutation,
  SubscriptionUpdateMutationVariables,
  SubscriptionUpdatePayload,
  SurveyReasonSubscriptionUpdateMutation,
  SurveyReasonSubscriptionUpdateMutationVariables,
} from "~/utilities/API/graphql";
import { PlanTier } from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { navigateProgrammaticallyTo } from "~/utilities/url/navigateProgrammaticallyTo";
import {
  getLocalStorageJSON,
  setLocalStorageJSON,
} from "utilities/localStorage";
import type { DowngradeSurveyResponse } from "~/jobber/plans/components/DowngradeModal/DowngradeModalContextProvider";
import { subscriptionChangeReasonKey } from "~/jobber/plans/components/DowngradeModal/DowngradeModalContextProvider";
import {
  CHECKOUT_EXPERIMENT,
  SUBSCRIPTION_UPDATE,
  SURVEY_REASON_SUBSCRIPTION_UPDATE,
} from "./Checkout.graphql";
import styles from "./Checkout.module.css";
import { CheckoutSummary } from "./components/CheckoutSummary";
import { DowngradeDisclaimer } from "./components/DowngradeDisclaimer/DowngradeDisclaimer";
import { MarketingSuiteAddonBanner } from "./components/MarketingSuiteAddon/MarketingSuiteAddonBanner";
import { MarketingSuiteAddonLoadingGlimmer } from "./components/MarketingSuiteAddon/MarketingSuiteAddonLoadingGlimmer";
import { SubscriptionAddonCards } from "./components/SubscriptionAddonCards/SubscriptionAddonCards";
import { messages } from "./messages";

interface CheckoutProps {
  recurlyPublicKey: string;
  planSetIdentifier: string;
  billingCycleName: BillingCycleName;
  subscriptionAddons: SubscriptionAddonPreview[];
  loadingAddons: boolean;
  preselectedAddons?: string[];
}

const trackingDetails: TrackingDetails = {
  name: "Checkout",
};

// eslint-disable-next-line max-statements
export function Checkout(checkoutProps: CheckoutProps) {
  const { formatMessage } = useIntl();
  const {
    recurlyPublicKey,
    planSetIdentifier,
    billingCycleName,
    subscriptionAddons,
    loadingAddons,
    preselectedAddons,
  } = checkoutProps;
  const editBillingInfoFormRef =
    useRef() as MutableRefObject<EditBillingInfoRef>;
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycleName>(billingCycleName);
  const [selectedAddonCodes, setSelectedAddonCodes] = useState<string[]>(
    preselectedAddons ?? [],
  );
  const [displayedPurchaseTotal, setDisplayedPurchaseTotal] = useState<
    number | undefined
  >();
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<FormErrorState>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { loading: checkoutExpLoading, data: checkoutExpTreatment } =
    useQuery(CHECKOUT_EXPERIMENT);
  const checkoutExpVariation = checkoutExpTreatment?.experiment?.variation;

  const { data: accountPlanInfoData, loading: accountPlanInfoLoading } =
    useQuery<AccountPlanInfoType>(ACCOUNT_PLAN_INFO);

  const [planDowngradingTo, setPlanDowngradingTo] = useState<
    PlanTier | undefined
  >(undefined);
  const [planDowngradingFrom, setPlanDowngradingFrom] = useState<
    PlanTier | undefined
  >(undefined);
  const [shouldSeeDowngradeDisclaimers, setShouldSeeDowngradeDisclaimers] =
    useState<boolean>(false);

  useEffect(() => {
    // This will track and show the success message when we have auto-added addons
    if (
      subscriptionAddons.length > 0 &&
      preselectedAddons?.length &&
      preselectedAddons?.length > 0
    ) {
      showToast({
        message: formatMessage(messages.successfulAutoAdded),
        variation: "success",
      });
      trackAutoAddedAddon({
        ...trackingDetails,
        addonName:
          preselectedAddons?.length > 1
            ? preselectedAddons.join(", ")
            : preselectedAddons[0],
        interaction: "addon_selection",
        action: "auto added",
      });
    }
  }, [preselectedAddons, formatMessage, subscriptionAddons]);

  // TODO JOB-110122 Remove after self-serve downgrade experiment
  const [changePlanSurveyReason] = useMutation<
    SurveyReasonSubscriptionUpdateMutation,
    SurveyReasonSubscriptionUpdateMutationVariables
  >(SURVEY_REASON_SUBSCRIPTION_UPDATE);
  // Due to this being an experiment, I chose to add on bits instead of modifying the checkout flow
  useEffect(() => {
    if (!accountPlanInfoLoading && accountPlanInfoData && planSetIdentifier) {
      const getPlanSet = (): PlanTier | undefined => {
        if (planSetIdentifier.toLowerCase().includes("grow")) {
          return PlanTier.GROW;
        }
        if (planSetIdentifier.toLowerCase().includes("connect")) {
          return PlanTier.CONNECT;
        }
        if (planSetIdentifier.toLowerCase().includes("core")) {
          return PlanTier.CORE;
        }
        return undefined;
      };
      setPlanDowngradingTo(getPlanSet());
      const planTierFrom = accountPlanInfoData?.accountPlanInfo
        .planTier as PlanTier;
      setPlanDowngradingFrom(planTierFrom);

      const downgradeOrder: Array<PlanTier | undefined> = [
        PlanTier.GROW,
        PlanTier.CONNECT,
        PlanTier.CORE,
      ];
      const fromIndex = downgradeOrder.indexOf(planDowngradingFrom);
      const toIndex = downgradeOrder.indexOf(planDowngradingTo);

      setShouldSeeDowngradeDisclaimers(fromIndex > -1 && fromIndex < toIndex);
    }
  }, [
    accountPlanInfoData,
    planSetIdentifier,
    accountPlanInfoLoading,
    planDowngradingFrom,
    planDowngradingTo,
  ]);

  const { setStoredUpdateBannerMessage, setStoredUpdateResult } =
    useStoredUpdateResult();

  const [subscriptionUpdate] = useMutation<
    SubscriptionUpdateMutation,
    SubscriptionUpdateMutationVariables
  >(SUBSCRIPTION_UPDATE);

  const hasValidationError = Object.values(validationErrors).some(
    error => !!error,
  );

  // COBRA KAI experiment: ticket to remove this code https://jobber.atlassian.net/browse/JOB-109858
  const { innerWidth } = useViewport();

  const checkoutBannerEnabled =
    checkoutExpVariation &&
    checkoutExpVariation !== "off" &&
    checkoutExpVariation !== "ineligible" &&
    innerWidth > 768;

  const isMarketingSuiteAvailable = subscriptionAddons.some(
    addon => addon.identifier === "marketing_suite",
  );

  return (
    <PurchaseFormContextProvider submitting={isSubmitting}>
      <div className={styles.container}>
        <Page
          title={
            shouldSeeDowngradeDisclaimers
              ? formatMessage(messages.downgradeTitle)
              : formatMessage(messages.title)
          }
        >
          <Grid>
            <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 8 }}>
              <PurchaseFormErrors errors={submissionErrors} />
              {checkoutBannerEnabled &&
                (loadingAddons || checkoutExpLoading) && (
                  <MarketingSuiteAddonLoadingGlimmer />
                )}
              {!isSubmitting &&
                checkoutBannerEnabled &&
                isMarketingSuiteAvailable && (
                  <MarketingSuiteAddonBanner
                    checkoutExpVariation={checkoutExpVariation}
                    subscriptionAddons={subscriptionAddons}
                    selectedAddonCodes={selectedAddonCodes}
                    setSelectedAddonCodes={setSelectedAddonCodes}
                  />
                )}
              {/* TODO JOB-110122 Remove after self-serve downgrade experiment */}
              {shouldSeeDowngradeDisclaimers &&
                !accountPlanInfoLoading &&
                planDowngradingTo &&
                planDowngradingFrom && (
                  <DowngradeDisclaimer
                    planDowngradingTo={planDowngradingTo}
                    planDowngradingFrom={planDowngradingFrom}
                  />
                )}
              <PrivacyMask>
                <EditBillingInfo
                  isPurchasing={true}
                  recurlyPublicKey={recurlyPublicKey}
                  ref={editBillingInfoFormRef}
                  trackingDetails={trackingDetails}
                  onSubmitSuccess={callPurchaseMutation}
                  onSubmitError={onSubmitError}
                  onValidationError={onValidationError}
                />
                {!isSubmitting && !checkoutBannerEnabled && (
                  <SubscriptionAddonCards
                    subscriptionAddons={subscriptionAddons}
                    selectedAddonCodes={selectedAddonCodes}
                    onChangeSelectedAddons={(
                      addonsAdded: string[],
                      addonsRemoved: string[],
                    ) => {
                      addonsAdded.forEach(addonCode => {
                        trackAddonSelection(addonCode, "added");
                      });
                      addonsRemoved.forEach(addonCode => {
                        if (selectedAddonCodes.includes(addonCode)) {
                          trackAddonSelection(addonCode, "removed");
                        }
                      });
                      setSelectedAddonCodes(
                        selectedAddonCodes
                          .filter(code => !addonsRemoved.includes(code))
                          .concat(addonsAdded),
                      );
                    }}
                  />
                )}
              </PrivacyMask>
            </Grid.Cell>
            <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
              <PrivacyMask disabled>
                <CheckoutSummary
                  trackingDetails={trackingDetails}
                  planSetIdentifier={planSetIdentifier}
                  selectedBillingCycle={selectedBillingCycle}
                  enablePurchaseButton={!hasValidationError}
                  subscriptionAddons={subscriptionAddons}
                  selectedAddonCodes={selectedAddonCodes}
                  displayedPurchaseTotal={displayedPurchaseTotal}
                  onSetSelectedBillingCycle={setSelectedBillingCycle}
                  onSetPurchaseTotal={handlePurchaseTotalUpdate}
                  onConfirmSubscription={handleConfirmSubscription}
                  setSelectedAddons={setSelectedAddonCodes}
                />
              </PrivacyMask>
            </Grid.Cell>
          </Grid>
        </Page>
      </div>
    </PurchaseFormContextProvider>
  );

  function handlePurchaseTotalUpdate(purchaseTotal: number) {
    setDisplayedPurchaseTotal(purchaseTotal);
  }

  async function handleConfirmSubscription() {
    setSubmissionErrors([]);
    setIsSubmitting(true);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: CONFIRM_SUBSCRIPTION_INTERACTION,
      addonCodes: selectedAddonCodes,
    });

    editBillingInfoFormRef.current &&
      (await editBillingInfoFormRef.current.submit());
  }

  async function callPurchaseMutation() {
    const result = await purchaseMutation();

    const userErrors: MutationErrors[] | undefined =
      result?.data?.subscriptionUpdate?.userErrors;

    const hasAddonErrors = hasAddonPurchaseError(userErrors);

    if (result?.data?.subscriptionUpdate?.success) {
      return onSubmitSuccess(result.data.subscriptionUpdate, hasAddonErrors);
    }
    if (userErrors?.length) {
      return onSubmitError(userErrors);
    }
  }

  async function purchaseMutation() {
    try {
      const result = await subscriptionUpdate({
        variables: {
          input: {
            planSetIdentifier: planSetIdentifier,
            billingCycleName: selectedBillingCycle,
            selectedAddonCodes: selectedAddonCodes,
            displayedPurchaseTotal: displayedPurchaseTotal
              ? formatCurrency(displayedPurchaseTotal, "$", 2)
              : undefined,
          },
        },
      });
      return result;
    } catch (error) {
      onSubmitError([
        { message: formatMessage(messages.defaultSubmitError), path: [] },
      ]);
    }
  }

  async function onSubmitSuccess(
    result: SubscriptionUpdatePayload,
    hasAddonErrors?: boolean,
  ) {
    if (result.shouldRecordFirstTimeSubscriptionEvents) {
      await handlePostSubscriptionUpdates();
    }

    if (shouldSeeDowngradeDisclaimers) {
      await submitSelfServeDowngradeSurvey();
    }

    const addonsMessage = hasAddonErrors
      ? result.userErrors[0].message
      : undefined;

    if (result.successRedirectUrl) {
      return redirect(result.successRedirectUrl, addonsMessage);
    }
  }

  async function submitSelfServeDowngradeSurvey() {
    try {
      const ssDowngradeSurveyReason = getLocalStorageJSON(
        subscriptionChangeReasonKey,
      ) as DowngradeSurveyResponse;

      if (ssDowngradeSurveyReason) {
        const surveyResult = await changePlanSurveyReason({
          variables: {
            reason: ssDowngradeSurveyReason.subChangeCategory,
            otherValue: ssDowngradeSurveyReason.subChangeReason,
          },
        });

        // Clear the local storage after the survey is submitted
        if (surveyResult.data?.upgradeReasonsSubscriptionUpdate?.success) {
          setLocalStorageJSON(subscriptionChangeReasonKey, "");
        }
      }
    } catch (error) {
      Rollbar.EXECUTE(
        `Self Serve Downgrade Survey submission failed: ${error.message}`,
        new Error("Checkout"),
      );
    }
  }

  function redirect(url: string, addonsMessage?: string) {
    if (addonsMessage) {
      setStoredUpdateBannerMessage(addonsMessage);
    } else {
      setStoredUpdateResult(formatMessage(messages.successfulUpdate));
    }
    navigateProgrammaticallyTo(url);
  }

  function onSubmitError(errors: MutationErrors[]) {
    setIsSubmitting(false);
    setSubmissionErrors(errors.map(error => error.message));
    trackFailedSubmitRecurlyForm(trackingDetails);
  }

  function onValidationError(newErrorState: FieldErrorState) {
    const fieldName = newErrorState.field;

    setValidationErrors({
      ...validationErrors,
      [fieldName]: newErrorState.message,
    });
  }

  async function handlePostSubscriptionUpdates() {
    const headers = new Headers([
      ["X-CSRF-Token", csrfToken],
      ["Content-type", "application/json"],
    ]);

    const request = new Request("checkout/set_first_subscription_events", {
      method: "PUT",
      credentials: "include",
      headers,
    });

    try {
      await fetch(request);
    } catch (error) {
      Rollbar.EXECUTE(
        `Post subscription updates failed: ${error.message}`,
        new Error("Checkout"),
      );
    }
  }

  function trackAddonSelection(addonCode: string, action: string) {
    const addonName = subscriptionAddons.find(
      addon => addon.monthlyBillingCycle?.addonCode === addonCode,
    )?.name;

    trackInteractedWithInput({
      ...trackingDetails,
      addonName: addonName,
      interaction: "addon_selection",
      action: action,
    });
  }
}

function hasAddonPurchaseError(userErrors: MutationErrors[] | undefined) {
  return userErrors?.some(userError => {
    return userError.path.includes("addons_purchase");
  });
}
