import React, { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import { Banner } from "@jobber/components/Banner";
import { IntlProvider } from "@translations/IntlProvider";
import { useConversationActionFunctions } from "jobber/chat/components/ChatDrawer/hooks/useConversationActionFunctions/useConversationActionFunctions";
import { TwilioRegistrationMessage } from "jobber/chat/components/ChatDrawer/TwilioRegistrationMessage";
import {
  ChatView,
  MessageCenterContext,
} from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import { ListHeader } from "jobber/chat/components/ChatDrawer/ListHeader";
import { NotificationListWrapper } from "jobber/chat/components/ChatDrawer/NotificationList/NotificationListWrapper";
import { ChatWrapper } from "jobber/chat/components/ChatDrawer/Chat/ChatWrapper";
import { SmsComposerPage } from "jobber/chat/components/SmsComposerPage";
import { UkKycRegistrationRequirementTypes } from "~/utilities/API/graphql";
import { ReassignConversationPage } from "jobber/chat/components/ReassignConversationPage/ReassignConversationPage";
import styles from "./ChatDrawer.module.css";

interface ChatDrawerProps {
  hasPhoneNumber: boolean;
  requestDrawerClose(): void;
}

const showConversationTypeFilter = false;

export function ChatDrawer({
  hasPhoneNumber,
  requestDrawerClose,
}: ChatDrawerProps) {
  const [state, dispatch] = useContext(MessageCenterContext);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { markUnread, deleteConversation, showReassignConversationScreen } =
    useConversationActionFunctions(
      state.selectedConversationId,
      dispatch,
      setErrorMessage,
    );

  const { ref, inView } = useInView();
  const [isVisible, setIsVisible] = useState(false);
  const isNotNotificationList = useMemo(
    () => state.activeView !== ChatView.Notifications,
    [state.activeView],
  );

  const showComposeMessageButton = (): boolean => {
    return (
      state?.ukKycComplianceRegistrationData?.registrationRequirement !==
        UkKycRegistrationRequirementTypes.HARD_BLOCK && hasPhoneNumber
    );
  };

  /* istanbul ignore next -- @preserve */
  function renderActiveView() {
    switch (state.activeView) {
      case ChatView.Notifications:
        return <></>;
      case ChatView.Compose:
        return <SmsComposerPage />;
      case ChatView.ReassignConversation:
        return <ReassignConversationPage />;
      case ChatView.RegistrationInProgress:
        return <TwilioRegistrationMessage status={state.registrationStatus} />;
      case ChatView.Conversation:
        return (
          state.selectedConversationId && (
            <IntlProvider>
              <ChatWrapper
                conversationId={state.selectedConversationId.toString()}
              />
            </IntlProvider>
          )
        );
      default:
        return <></>;
    }
  }

  useEffect(() => {
    if (inView && !isVisible) {
      setIsVisible(true);
    }

    if (!inView && isVisible) {
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <div ref={ref} className={classNames(styles.drawer)}>
      <ListHeader
        showCompose={showComposeMessageButton()}
        requestDrawerClose={requestDrawerClose}
        markUnread={markUnread}
        deleteConversation={deleteConversation}
        reassignConversation={showReassignConversationScreen}
      />
      {errorMessage && (
        <div className={styles.errorWrapper}>
          <Banner
            type="error"
            dismissible={true}
            onDismiss={() => setErrorMessage(undefined)}
          >
            {errorMessage}
          </Banner>
        </div>
      )}
      {isVisible && (
        <>
          <div
            className={buildNotificationWrapperClassNames(
              isNotNotificationList,
              showConversationTypeFilter,
            )}
          >
            <NotificationListWrapper
              hasPhoneNumber={hasPhoneNumber}
              showNotificationList={state.activeView === ChatView.Notifications}
              showConversationTypeFilter={showConversationTypeFilter}
            />
          </div>
          {renderActiveView()}
        </>
      )}
    </div>
  );
}

function buildNotificationWrapperClassNames(
  isNotNotificationList: boolean,
  showingConversationTypeFilter: boolean,
): string {
  let cssClasses;
  if (isNotNotificationList) {
    cssClasses = [styles.invisible, styles.h0];
  } else if (showingConversationTypeFilter) {
    cssClasses = [styles.visible, styles.withConversationFilter];
  } else {
    cssClasses = [styles.visible];
  }

  return classNames(cssClasses);
}
