import React from "react";
import { Heading } from "@jobber/components/Heading";
import { DescriptionList } from "@jobber/components/DescriptionList";
import { Text } from "@jobber/components/Text";
import classNames from "classnames";
import styles from "./ScheduleSummary.module.css";

export const ScheduleSummary = (props: {
  disabled: boolean;
  startDate: string;
  endDate: string;
  totalOccurences: number;
  summaryString: string;
}) => {
  const containerClass = classNames(
    styles.summaryContainer,
    props.disabled ? styles.disabled : "",
  );

  return (
    <div className={containerClass}>
      <Heading level={5}>Summary</Heading>
      <Text>{props.summaryString}</Text>
      <DescriptionList
        data={[
          ["First", `${props.startDate}`],
          ["Last", `${props.endDate}`],
          ["Total visits", `${props.totalOccurences}`],
        ]}
      />
    </div>
  );
};
