import React, { useEffect, useState } from "react";
import { showToast } from "@jobber/components/Toast";
import { useMutation } from "@apollo/client";
import type {
  AppRequestCreateMutation,
  AppRequestCreateMutationVariables,
  MutationErrors,
} from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from "jobber/marketplace/ANALYTICS_EVENTS";
import { APP_REQUEST_CREATE_MUTATION } from "./IntegrationRequest.graphql";
import { IntegrationRequest } from "./IntegrationRequest";

export function IntegrationRequestLoader({
  defaultName,
  publicFacing,
}: {
  defaultName?: string;
  publicFacing: boolean;
}) {
  return (
    <BaseIntegrationRequestLoader
      defaultName={defaultName}
      publicFacing={publicFacing}
    />
  );
}

function BaseIntegrationRequestLoader({
  defaultName,
  publicFacing,
}: {
  defaultName?: string;
  publicFacing: boolean;
}) {
  const [name, setName] = useState(defaultName || "");
  const [description, setDescription] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [appRequestCreate, { loading }] = useMutation<
    AppRequestCreateMutation,
    AppRequestCreateMutationVariables
  >(APP_REQUEST_CREATE_MUTATION);

  useEffect(() => {
    setName(defaultName || "");
  }, [defaultName]);

  function handleError(error: string) {
    showToast({ message: error, variation: "error" });
  }

  async function onAppRequestCreate() {
    const payload = {
      variables: {
        input: {
          appName: name,
          description: description,
        },
      },
    };

    try {
      const result = await appRequestCreate(payload);
      const errors = result.data?.appRequestCreate
        .userErrors as MutationErrors[];

      if (errors.length > 0) {
        handleError(errors[0].message);
      } else {
        setModalOpen(false);
        showToast({
          variation: "success",
          message: "Thanks for the request, we'll look into it",
        });
        Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.dialogInteraction, {
          source: ANALYTICS_PROPERTIES.requestAnAppSource,
        });
      }
    } catch (createError) {
      handleError("Something went wrong. Please try again.");
    }
  }

  return (
    <IntegrationRequest
      name={name}
      description={description}
      modalOpen={modalOpen}
      loading={loading}
      setModalOpen={setModalOpen}
      onChangeName={setName}
      onChangeDescription={setDescription}
      onAppRequestCreate={onAppRequestCreate}
      publicFacing={publicFacing}
    />
  );
}
