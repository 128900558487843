/* eslint-disable import/no-internal-modules */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Chip, Chips } from "@jobber/components/Chips";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Glimmer } from "@jobber/components/Glimmer";
import { showToast } from "@jobber/components/Toast";
import { InputValidation } from "@jobber/components/InputValidation";
import { messages } from "./messages";
import styles from "./styles.module.css";
import { ConfigurationSetting } from "../ConfigurationSetting/ConfigurationSetting";
import { useUpdateSchedule } from "../../hooks/useUpdateSchedule";

enum IntervalDurationMinutes {
  MINUTES_30 = "MINUTES_30",
  MINUTES_60 = "MINUTES_60",
  MINUTES_120 = "MINUTES_120",
  MINUTES_180 = "MINUTES_180",
  MINUTES_240 = "MINUTES_240",
}

interface BookingIntervalConfigurationProps {
  intervalDuration?: number;
}

export function BookingIntervalConfiguration({
  intervalDuration,
}: BookingIntervalConfigurationProps) {
  return intervalDuration ? (
    <SlotIntervalContent intervalDuration={intervalDuration} />
  ) : (
    <SlotIntervalContentSkeleton />
  );
}

function SlotIntervalContent({
  intervalDuration,
}: BookingIntervalConfigurationProps) {
  const { editSelfServeScheduleSettings } = useUpdateSchedule();
  const { formatMessage } = useIntl();
  const [interval, setInterval] = useState<string>(
    `MINUTES_${intervalDuration}`,
  );
  const [validationMessages, setValidationMessages] = useState<string>();
  const successMessage = formatMessage(messages.settingSaved);
  const errorMessage = formatMessage(messages.errorMessage);

  function saveInterval(value: IntervalDurationMinutes) {
    setInterval(value);
    setValidationMessages(undefined);
    editSelfServeScheduleSettings({ intervalDuration: value })
      .then(() => {
        showToast({
          message: successMessage,
        });
      })
      .catch(() => {
        setInterval(`MINUTES_${intervalDuration}`);
        setValidationMessages(errorMessage);
      });
  }

  return (
    <ConfigurationSetting
      title={formatMessage(messages.configurationLabel)}
      description={formatMessage(messages.configurationDescription)}
    >
      <div className={styles.chips}>
        <Chips
          name={"intervalDuration"}
          selected={interval}
          onChange={(value: IntervalDurationMinutes) => saveInterval(value)}
          type={"singleselect"}
        >
          <Chip
            label={formatMessage(messages.halfHour)}
            value={IntervalDurationMinutes.MINUTES_30}
          />
          <Chip
            label={formatMessage(messages.oneHour)}
            value={IntervalDurationMinutes.MINUTES_60}
          />
          <Chip
            label={formatMessage(messages.twoHours)}
            value={IntervalDurationMinutes.MINUTES_120}
          />
          <Chip
            label={formatMessage(messages.threeHours)}
            value={IntervalDurationMinutes.MINUTES_180}
          />
          <Chip
            label={formatMessage(messages.fourHours)}
            value={IntervalDurationMinutes.MINUTES_240}
          />
        </Chips>
      </div>
      {validationMessages && <InputValidation message={validationMessages} />}
      <GenerateDescription duration={interval} />
    </ConfigurationSetting>
  );
}

function SlotIntervalContentSkeleton() {
  return (
    <>
      <Glimmer.Header></Glimmer.Header>
      <Glimmer.Text></Glimmer.Text>
      <span>
        <Glimmer.Button></Glimmer.Button>
        <Glimmer.Button></Glimmer.Button>
        <Glimmer.Button></Glimmer.Button>
        <Glimmer.Button></Glimmer.Button>
      </span>
      <Glimmer.Text></Glimmer.Text>
    </>
  );
}

function GenerateDescription({ duration }: { duration: string }) {
  const { formatMessage } = useIntl();

  return (
    <Text>
      {formatMessage(messages.explanationDescriptionPartial)}
      <Emphasis variation="bold">
        {formatMessage(timeDescriptionFormat(duration))}
      </Emphasis>
      {formatMessage(messages.visualizerDescription)}
      {formatMessage(calculateTimeIncrements(duration))}
    </Text>
  );
}

function timeDescriptionFormat(duration: string) {
  switch (duration) {
    case IntervalDurationMinutes.MINUTES_30:
    default:
      return messages.halfHourDescription;
    case IntervalDurationMinutes.MINUTES_60:
      return messages.oneHourDescription;
    case IntervalDurationMinutes.MINUTES_120:
      return messages.twoHoursDescription;
    case IntervalDurationMinutes.MINUTES_180:
      return messages.threeHoursDescription;
    case IntervalDurationMinutes.MINUTES_240:
      return messages.fourHoursDescription;
  }
}

function calculateTimeIncrements(duration: string) {
  switch (duration) {
    case IntervalDurationMinutes.MINUTES_30:
    default:
      return messages.halfHoursVisualized;
    case IntervalDurationMinutes.MINUTES_60:
      return messages.oneHourVisualized;
    case IntervalDurationMinutes.MINUTES_120:
      return messages.twoHoursVisualized;
    case IntervalDurationMinutes.MINUTES_180:
      return messages.threeHoursVisualized;
    case IntervalDurationMinutes.MINUTES_240:
      return messages.fourHoursVisualized;
  }
}
