import { useIntl } from "react-intl";
import WinBackLostLeads from "@images/CampaignWinLostClientsTemplate.png";
import type { HeaderCard } from "components/TopHeaderCards/HeaderCard";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import { Template } from "~/utilities/API/graphql";
import { updatedCampaignAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import ReEngagePastClients from "@images/CampaignReEngagePastClientsTemplate.png";
import StartFromScratch from "@images/CampaignStartFromScratchTemplate.png";
import styles from "./useHeaderCardsOptions.module.css";
import { messages } from "./messages";

interface useHeaderCardsOptionsProps {
  isTemplateLoading: boolean;
}

export function useHeaderCardsOptions({
  isTemplateLoading,
}: useHeaderCardsOptionsProps): HeaderCard[] {
  const { newCampaignNavigationURL } = useCampaignUpsellSplit();
  const { formatMessage } = useIntl();
  const actionDefaults = {
    label: formatMessage(messages.useTemplate),
    id: styles.useTemplateButton,
    type: "secondary" as const,
    size: "small" as const,
    loading: isTemplateLoading,
    onClick: () =>
      updatedCampaignAmplitudeEvent({
        interaction: "Started",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entry_point: "template_card",
        // eslint-enable-next-line @typescript-eslint/naming-convention
      }),
  };

  const cardOptions = () => {
    const cards = [
      {
        title: formatMessage(messages.automatedReEngagePastClientsTitle),
        description: formatMessage(
          messages.automatedReEngagePastClientsDescription,
        ),
        img: {
          src: ReEngagePastClients,
          alt: formatMessage(messages.automatedReEngagePastClientsImgAlt),
        },
        action: {
          ...actionDefaults,
          to: newCampaignNavigationURL(Template.AUTOMATED_RE_ENGAGE, true),
        },
        inlineLabel: formatMessage(messages.automatedCampaignType),
      },
      {
        title: formatMessage(messages.automatedWinBackLostLeadsTitle),
        description: formatMessage(
          messages.automatedWinBackLostLeadsDescription,
        ),
        img: {
          src: WinBackLostLeads,
          alt: formatMessage(messages.automatedWinBackLostLeadsImgAlt),
        },
        action: {
          ...actionDefaults,
          to: newCampaignNavigationURL(
            Template.AUTOMATED_WIN_BACK_LOST_LEADS,
            true,
          ),
        },
        inlineLabel: formatMessage(messages.automatedCampaignType),
      },
      {
        title: formatMessage(messages.oneOffStartFromScratchTitle),
        description: formatMessage(messages.oneOffStartFromScratchDescription),
        img: {
          src: StartFromScratch,
          alt: formatMessage(messages.oneOffStartFromScratchImgAlt),
        },
        action: {
          ...actionDefaults,
          to: newCampaignNavigationURL(Template.START_FROM_SCRATCH, true),
        },
        inlineLabel: formatMessage(messages.oneOffCampaignType),
      },
    ];

    return cards;
  };

  return [...cardOptions()];
}
