import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYesterday,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import type { dateRangeOptionIndexType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/types";

// These strings are related to the Intl keys in messages.ts
export enum DateRangeOptions {
  today = "dateRangeToday",
  yesterday = "dateRangeYesterday",
  thisWeek = "dateRangeThisWeek",
  lastWeek = "dateRangeLastWeek",
  thirtyDays = "dateRangeThirtyDays",
  thisMonth = "dateRangeThisMonth",
  lastMonth = "dateRangeLastMonth",
  year = "dateRangeYear",
  twelveMonths = "dateRangeTwelveMonths",
  custom = "dateRangeCustom",
}

// These strings are the values of th Intl keys in messages.ts that relate to the keys of the above enum
export enum DateRangeSelectOptions {
  today = "Today",
  yesterday = "Yesterday",
  thisWeek = "This week",
  lastWeek = "Last week",
  thirtyDays = "Last 30 days",
  thisMonth = "This month",
  lastMonth = "Last month",
  year = "This year",
  twelveMonths = "Last 12 months",
  custom = "Custom range",
}

export const dateRangeOptionIndex: dateRangeOptionIndexType[] = [
  {
    option: DateRangeOptions.today,
    start: startOfToday(),
    end: endOfToday(),
  },
  {
    option: DateRangeOptions.yesterday,
    start: startOfYesterday(),
    end: endOfYesterday(),
  },
  {
    option: DateRangeOptions.thisWeek,
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
  },
  {
    option: DateRangeOptions.lastWeek,
    start: subWeeks(startOfWeek(new Date()), 1),
    end: subWeeks(endOfWeek(new Date()), 1),
  },
  {
    option: DateRangeOptions.thirtyDays,
    start: subDays(startOfToday(), 30),
    end: endOfToday(),
  },
  {
    option: DateRangeOptions.thisMonth,
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  },
  {
    option: DateRangeOptions.lastMonth,
    start: subMonths(startOfMonth(new Date()), 1),
    end: subMonths(endOfMonth(new Date()), 1),
  },
  {
    option: DateRangeOptions.year,
    start: new Date(new Date().getFullYear(), 0, 1),
    end: endOfToday(),
  },
  {
    option: DateRangeOptions.twelveMonths,
    start: subMonths(startOfToday(), 12),
    end: endOfToday(),
  },
];

// Should be last 30 days (DateRangeOptions.thirtyDays)
export const defaultDateRangeOption = dateRangeOptionIndex[4];
