/* eslint-disable max-statements */
import type { MutableRefObject } from "react";
import React, { useEffect, useRef, useState } from "react";
import { Page } from "@jobber/components/Page";
import { useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import { Tab, Tabs } from "@jobber/components";
import { useHistory } from "react-router-dom";
import {
  activatedCampaignsAmplitudeEvent,
  updatedCampaignAmplitudeEvent,
} from "jobber/campaigns/amplitude/events";
import { TopHeaderCards } from "components/TopHeaderCards";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import {
  useCampaignDataListItems,
  useCampaignsQuery,
} from "jobber/campaigns/views/CampaignsLandingPage/hooks";
import { useCommsCampaignTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignTemplateQuery";
import { CampaignsFilterFields } from "~/utilities/API/graphql";
import { useCampaignUpsellSplit } from "jobber/campaigns/hooks/useCampaignUpsellSplit";
import { SentDelighterModal } from "jobber/campaigns/components/SentDelighterModal/SentDelighterModal";
import { DeleteCampaignModal } from "jobber/campaigns/views/CampaignsLandingPage/components/DeleteCampaignModal/DeleteCampaignModal";
import { PauseAndEditModal } from "jobber/campaigns/components/PauseAndEditModal/PauseAndEditModal";
import { SendNowModal } from "jobber/campaigns/components/SendNowModal/SendNowModal";
import { useCampaignAutomationsSplit } from "jobber/campaigns/hooks/useCampaignAutomationsSplit/useCampaignAutomationsSplit";
import { AutomatedCampaignList } from "jobber/campaigns/views/CampaignsLandingPage/components/AutomatedCampaignList/AutomatedCampaignList";
import { useCampaignsQueryVariables } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignsQueryVariables";
import { ReactivateCampaignModal } from "jobber/campaigns/components/ReactivateCampaignModal/ReactivateCampaignModal";
import { DeactivateCampaignModal } from "jobber/campaigns/components/DeactivateCampaignModal/DeactivateCampaignModal";
import { AutomatedPauseAndEditModal } from "jobber/campaigns/components/AutomatedPauseAndEditModal/AutomatedPauseAndEditModal";
import { useUrlQueryParam } from "jobber/campaigns/hooks/useUrlQueryParam/useUrlQueryParam";
import { useHeaderCardsOptions } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useHeaderCardsOptions/useHeaderCardsOptions";
import {
  CallToAction,
  type CallToActionRef,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { messages } from "./messages";
import styles from "./CampaignsLandingPage.module.css";
import { CampaignList } from "./components/CampaignList/CampaignList";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  URL_PARAM_CAMPAIGNS_TYPE,
} from "../../constants";

export function CampaignsLandingPage() {
  return (
    <APIProvider>
      <IntlProvider>
        <CampaignsLandingPageWrapper />
      </IntlProvider>
    </APIProvider>
  );
}

function CampaignsLandingPageWrapper(): JSX.Element {
  const history = useHistory();
  const { urlParam } = useUrlQueryParam(URL_PARAM_CAMPAIGNS_TYPE);

  const [selectedCampaignId, setSelectedCampaignId] = useState(""); // used for delete campaign modal

  const [campaignActionsError, setCampaignActionsError] = useState<
    string | undefined
  >();
  const { formatMessage } = useIntl();

  const {
    filter,
    actions: { setFilterType },
  } = useCampaignsQueryVariables(urlParam as CampaignsFilterFields);

  const {
    data,
    error: campaignsQueryError,
    loadingInitialContent,
    loadingNextPage,
    nextPage,
    setSortVariable,
    refresh,
  } = useCampaignsQuery(filter.filter);

  const { loading: loadingTemplate, template } =
    useCommsCampaignTemplateQuery();

  const { isInCampaignAutomations } = useCampaignAutomationsSplit();
  const templates = useHeaderCardsOptions({
    isTemplateLoading: loadingTemplate,
  });

  const totalCount = data?.commsCampaigns.totalCount;
  const campaignDataList = useCampaignDataListItems({
    defaultSubject: template?.subject,
  });

  const campaigns = data?.commsCampaigns?.edges?.map(campaignDataList) || [];
  const { newCampaignNavigationURL } = useCampaignUpsellSplit();
  useEffect(() => {
    activatedCampaignsAmplitudeEvent({
      interaction: "Page Viewed",
    });
  }, []);

  const newCampaignURL = newCampaignNavigationURL();

  const dismissErrorBanner = () => {
    setCampaignActionsError(undefined);
  };

  useEffect(() => {
    if (urlParam && urlParam.includes(CampaignsFilterFields.AUTOMATED)) {
      setFilterType(urlParam as CampaignsFilterFields);
    }
  }, [setFilterType, urlParam]);

  const handleOnClick = (type: CampaignsFilterFields) => {
    history.replace(
      `${CAMPAIGNS_LANDING_PAGE_PATH}?${URL_PARAM_CAMPAIGNS_TYPE}=${type}`,
    );
    setFilterType(type);
  };
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const dismissBanner = () => {
    dismissCTA(ctaRef)();
  };

  return (
    <div className={styles.container}>
      <Page
        title={formatMessage(messages.campaigns)}
        width="fill"
        primaryAction={
          !loadingInitialContent && !loadingTemplate
            ? {
                label: formatMessage(messages.newCampaignLabel),
                to: newCampaignURL,
                loading: loadingInitialContent,
                onClick: () =>
                  updatedCampaignAmplitudeEvent({
                    interaction: "Started",
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    entry_point: "CTA",
                  }),
              }
            : undefined
        }
      >
        {campaignActionsError && !campaignsQueryError && (
          <Banner type={"error"} onDismiss={dismissErrorBanner}>
            {campaignActionsError}
          </Banner>
        )}
        {campaignsQueryError && (
          <Banner type={"error"}>{formatMessage(messages.errorMessage)}</Banner>
        )}
        <CallToAction ctaName="show_campaigns_banner" ref={ctaRef}>
          <TopHeaderCards
            title={formatMessage(messages.chooseATemplate)}
            visible={true}
            cards={templates}
            onDismiss={dismissBanner}
          />
        </CallToAction>
      </Page>
      <PauseAndEditModal setCampaignActionsError={setCampaignActionsError} />
      <ReactivateCampaignModal
        setCampaignActionsError={setCampaignActionsError}
      />
      <DeactivateCampaignModal
        setCampaignActionsError={setCampaignActionsError}
      />
      <AutomatedPauseAndEditModal
        setCampaignActionsError={setCampaignActionsError}
      />
      <SendNowModal
        setCampaignActionsError={setCampaignActionsError}
        refreshCampaignList={refresh}
      />
      <SentDelighterModal />
      <DeleteCampaignModal
        isOpen={!!selectedCampaignId}
        campaignId={selectedCampaignId}
        setSelectedCampaignId={setSelectedCampaignId}
        setCampaignActionsError={setCampaignActionsError}
        refreshCampaignList={refresh}
      />
      {isInCampaignAutomations && (
        <div className={styles.campaignListTabContainer}>
          <Tabs key={filter.tabIndex} defaultTab={filter.tabIndex}>
            <Tab
              label={formatMessage(messages.oneOffCampaignsTabTitle)}
              onClick={() => handleOnClick(CampaignsFilterFields.ONEOFF)}
            >
              <CampaignList
                campaigns={campaigns}
                totalCount={totalCount}
                loadingInitial={loadingInitialContent || loadingTemplate}
                loadingMore={loadingNextPage}
                setVariablesForSorting={setSortVariable}
                loadMore={nextPage}
                setSelectedCampaignId={setSelectedCampaignId}
              />
            </Tab>
            <Tab
              label={formatMessage(messages.automatedCampaignsTabTitle)}
              onClick={() => handleOnClick(CampaignsFilterFields.AUTOMATED)}
            >
              <AutomatedCampaignList
                campaigns={campaigns}
                totalCount={totalCount}
                loadingInitial={loadingInitialContent || loadingTemplate}
                loadingMore={loadingNextPage}
                setVariablesForSorting={setSortVariable}
                loadMore={nextPage}
                setSelectedCampaignId={setSelectedCampaignId}
              />
            </Tab>
          </Tabs>
        </div>
      )}
      {!campaignsQueryError && !isInCampaignAutomations && (
        <div className={styles.campaignListContainer}>
          <CampaignList
            campaigns={campaigns}
            totalCount={totalCount}
            loadingInitial={loadingInitialContent || loadingTemplate}
            loadingMore={loadingNextPage}
            setVariablesForSorting={setSortVariable}
            loadMore={nextPage}
            setSelectedCampaignId={setSelectedCampaignId}
          />
        </div>
      )}
    </div>
  );
}
