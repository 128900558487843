import type { ReactNode } from "react";
import { createContext, useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import type {
  AccountTierInfoQuery,
  SubscriptionPermissions,
  UserLimitsPlanInfoQuery,
  UserLimitsPlanInfoQueryVariables,
} from "~/utilities/API/graphql";
import { SAVE_OFFER_ELIGIBILITY } from "legacy/jobber/downgradePage/components/DowngradeButton/SaveOfferEligibilityQuery.graphql";
import { setLocalStorageJSON } from "utilities/localStorage";
import { DowngradeFlowStepEnum } from "./downgradeSteps";
import { ACCOUNT_TIER_INFO, USER_LIMITS_PLAN_INFO } from "./Downgrade.graphql";

export const subscriptionChangeReasonKey = "ss_dg_sub_change_reason";

export interface ActionProps {
  disabled: boolean;
  label?: string;
  labelArgs?: { [key: string]: string };
  loading: boolean;
  onClick: () => void;
}

export interface DowngradeSurveyResponse {
  subChangeReason: string;
  subChangeCategory: string;
}

export interface DowngradePlanInfo {
  planCode: string;
  userLimit: number | null;
  planTier: string;
}

export interface UserLimitsPlanInfoType {
  userLimitsData: UserLimitsPlanInfoQuery | undefined;
  userLimitsLoading: boolean;
}

export interface DowngradeModalContextValue {
  currentStep: DowngradeFlowStepEnum;
  errorMessage: string;
  primaryAction?: ActionProps;
  secondaryAction?: ActionProps;
  subscriptionChangeReason?: DowngradeSurveyResponse;
  additionalTrackingProps?: Record<string, string>;
  setCurrentStep: (step: DowngradeFlowStepEnum) => void;
  setErrorMessage: (message: string) => void;
  setPrimaryAction: (action: ActionProps) => void;
  setSecondaryAction: (action?: ActionProps) => void;
  setSubscriptionChangeReason: (reason: DowngradeSurveyResponse) => void;
  setAdditionalTrackingProps: (props: Record<string, string>) => void;
  closeModal: () => void;
  accountTier: string | undefined;
  downgradePlanInfo: DowngradePlanInfo;
  accountTierInfoLoading: boolean;
  userLimitsPlanInfo: UserLimitsPlanInfoType;
  promoEligible: boolean;
  accountIndustry: string;
  requiresUserDeactivation: boolean;
}

export const DowngradeModalContext =
  createContext<DowngradeModalContextValue | null>(null);

// eslint-disable-next-line max-statements
export function DowngradeModalContextProvider({
  children,
  downgradePlanInfo,
  accountIndustry,
  onClose,
  initialStep = DowngradeFlowStepEnum.DowngradeSurvey,
}: {
  children: ReactNode;
  accountIndustry: string;
  downgradePlanInfo: DowngradePlanInfo;
  onClose: () => void;
  initialStep?: DowngradeFlowStepEnum;
}) {
  const [modalState, setModalState] = useState<{
    currentStep: DowngradeFlowStepEnum;
    errorMessage: string;
    primaryAction?: ActionProps;
    secondaryAction?: ActionProps;
    subscriptionChangeReason?: DowngradeSurveyResponse;
    additionalTrackingProps?: Record<string, string>;
  }>({
    currentStep: initialStep,
    errorMessage: "",
    primaryAction: undefined,
    secondaryAction: undefined,
    subscriptionChangeReason: undefined,
    additionalTrackingProps: undefined,
  });

  const downgradePlanInfoValue = useMemo(
    () => downgradePlanInfo,
    [downgradePlanInfo],
  );

  const setCurrentStep = useCallback((step: DowngradeFlowStepEnum) => {
    setModalState(state => ({
      ...state,
      currentStep: step,
      // reset tracking props when navigating to a new step
      additionalTrackingProps: undefined,
    }));
  }, []);

  const setErrorMessage = useCallback((message: string) => {
    setModalState(state => ({
      ...state,
      errorMessage: message,
    }));
  }, []);

  const setPrimaryAction = useCallback((action?: ActionProps) => {
    setModalState(state => ({
      ...state,
      primaryAction: action,
    }));
  }, []);

  const setSecondaryAction = useCallback((action?: ActionProps) => {
    setModalState(state => ({
      ...state,
      secondaryAction: action,
    }));
  }, []);

  const setAdditionalTrackingProps = useCallback(
    (props: Record<string, string>) => {
      setModalState(state => ({
        ...state,
        additionalTrackingProps: props,
      }));
    },
    [],
  );

  const { data: accountTierInfoData, loading: accountTierInfoLoading } =
    useQuery<AccountTierInfoQuery>(ACCOUNT_TIER_INFO);

  const { data: subscriptionPermissionsData } = useQuery<{
    subscriptionPermissions: SubscriptionPermissions;
  }>(SAVE_OFFER_ELIGIBILITY);

  const { data: userLimitsData, loading: userLimitsLoading } = useQuery<
    UserLimitsPlanInfoQuery,
    UserLimitsPlanInfoQueryVariables
  >(USER_LIMITS_PLAN_INFO);

  const setSubscriptionChangeReason = useCallback(
    (reason: DowngradeSurveyResponse) => {
      setLocalStorageJSON(subscriptionChangeReasonKey, reason);
      setModalState(state => ({
        ...state,
        subscriptionChangeReason: reason,
      }));
    },
    [],
  );

  const requiresUserDeactivation = useMemo(() => {
    return (
      (userLimitsData?.users?.totalCount || 1) >
      (downgradePlanInfo.userLimit || 1)
    );
  }, [downgradePlanInfo.userLimit, userLimitsData?.users?.totalCount]);

  return (
    <DowngradeModalContext.Provider
      value={{
        currentStep: modalState.currentStep,
        errorMessage: modalState.errorMessage,
        primaryAction: modalState.primaryAction,
        secondaryAction: modalState.secondaryAction,
        subscriptionChangeReason: modalState.subscriptionChangeReason,
        additionalTrackingProps: modalState.additionalTrackingProps,
        setCurrentStep,
        setErrorMessage,
        setPrimaryAction,
        setSecondaryAction,
        setSubscriptionChangeReason,
        setAdditionalTrackingProps,
        closeModal: onClose,
        accountTier: accountTierInfoData?.accountPlanInfo?.planTier,
        downgradePlanInfo: downgradePlanInfoValue,
        accountTierInfoLoading: accountTierInfoLoading,
        userLimitsPlanInfo: {
          userLimitsData,
          userLimitsLoading,
        },
        promoEligible:
          subscriptionPermissionsData?.subscriptionPermissions
            ?.canAcceptDowngradeSaveOffer ?? false,
        accountIndustry,
        requiresUserDeactivation,
      }}
    >
      {children}
    </DowngradeModalContext.Provider>
  );
}
