import { Content, Modal, Text } from "@jobber/components";
import type { Dispatch, SetStateAction } from "react";
import React from "react";

export const MarketingToolAddToCartModal = ({
  addonName,
  isOpen,
  setModalOpen,
}: {
  addonName: string;
  isOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Modal
      title={`${addonName} added to cart`}
      open={isOpen}
      onRequestClose={() => setModalOpen(false)}
      primaryAction={{
        label: "Continue to checkout",
        onClick: () => {
          setModalOpen(false);
          window.location.href = "/accounts/billing_info/pricing";
        },
      }}
      secondaryAction={{
        label: "Stay on page",
        onClick: () => setModalOpen(false),
      }}
    >
      <Content>
        <Text>
          {`You're one step closer to unlocking powerful marketing tools to grow
          your business. Continue to checkout to select a plan.`}
        </Text>
      </Content>
    </Modal>
  );
};
