/**
 * This function wraps setting the window.location.href.
 *
 * One reason we do this to enable mocking of _just_ this functionality.
 * It is challenging to properly mock window.location
 * in a test that doesn't lead to flakes.
 */
export function navigateProgrammaticallyTo(
  url?: string,
  options?:
    | {
        external?: never;
        preserveURLSearchParams?: boolean;
        replace: boolean;
      }
    | {
        external: boolean;
        preserveURLSearchParams?: never;
        replace?: never;
        features?: string;
      },
) {
  if (!url) {
    throw new Error("URL is required");
  }

  if (options?.external) {
    window.open(url, "_blank", options.features);
    return;
  }

  if (options?.replace) {
    const newUrl = new URL(
      options.preserveURLSearchParams
        ? window.location.href
        : window.location.origin,
    );
    newUrl.pathname = url;
    window.location.replace(newUrl);
    return;
  }

  window.location.assign(url);
}
