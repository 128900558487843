import React from "react";
import { Text } from "@jobber/components/Text";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { InputText } from "@jobber/components/InputText";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from "jobber/marketplace/ANALYTICS_EVENTS";

interface IntegrationRequestModalProps {
  name: string;
  description: string;
  modalOpen: boolean;
  loading: boolean;
  setModalOpen(modalOpen: boolean): void;
  onAppRequestCreate(): void;
  onChangeName(name: string): void;
  onChangeDescription(name: string): void;
}

export function IntegrationRequestModal({
  name,
  description,
  modalOpen,
  loading,
  setModalOpen,
  onAppRequestCreate,
  onChangeName,
  onChangeDescription,
}: IntegrationRequestModalProps) {
  function modalClose() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.dialogInteraction, {
      source: ANALYTICS_PROPERTIES.requestAnAppCancelledSource,
    });
    setModalOpen(false);
  }

  return (
    <Modal
      title="App request"
      open={modalOpen}
      primaryAction={{
        label: "Send Request",
        loading: loading,
        onClick: onAppRequestCreate,
      }}
      secondaryAction={{ label: "Cancel", onClick: modalClose }}
      onRequestClose={modalClose}
    >
      <Content>
        <Text>
          Fill in the form below to request an integration you would like to see
          in Jobber&apos;s App Marketplace
        </Text>

        <InputText
          value={name}
          placeholder="Name of the app"
          onChange={onChangeName}
        />
        <InputText
          value={description}
          multiline={true}
          placeholder="In a few words, describe the problem you were hoping to solve with this app?"
          onChange={onChangeDescription}
        />
      </Content>
    </Modal>
  );
}
