import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { QuoteDetailReport } from "jobber/franchise/features/Reporting/views/QuoteDetailReport";
import { NoMatch, join } from "jobber/settings/users/utilities/routing";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import { RoyaltyReport } from "jobber/franchise/features/Reporting/views/RoyaltyReport";
import { InvoiceSummaryReport } from "jobber/franchise/features/Reporting/views/InvoiceSummaryReport";
import { QuoteSummaryReport } from "jobber/franchise/features/Reporting/views/QuoteSummaryReport";
import { InvoiceDetailReport } from "jobber/franchise/features/Reporting/views/InvoiceDetailReport";
import { IntlProvider } from "@translations/IntlProvider";

export interface ReportingRouteProps {
  readonly routing: {
    base: string;
  };
}

export const ReportingRoute = withEntryPointProviders<ReportingRouteProps>(
  () => {
    const { path } = useRouteMatch();
    return (
      <IntlProvider>
        <Switch>
          <Route exact path={join(path, "royalty_report")}>
            <RoyaltyReport />
          </Route>
          <Route exact path={join(path, "invoice_summary")}>
            <InvoiceSummaryReport />
          </Route>
          <Route exact path={join(path, "quote_summary")}>
            <QuoteSummaryReport />
          </Route>
          <Route exact path={join(path, "invoice_detail")}>
            <InvoiceDetailReport />
          </Route>
          <Route exact path={join(path, "quote_detail")}>
            <QuoteDetailReport />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </IntlProvider>
    );
  },
);
