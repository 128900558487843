import { Button, Content } from "@jobber/components";
import React from "react";

export const AddMarketingToolToCartButton = ({
  addonName,
  addonIdentifier,
  setMarketingToolModalOpen,
}: {
  addonName: string;
  addonIdentifier: string;
  setMarketingToolModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Content>
      <Button
        label={`Add ` + addonName + ` to cart`}
        ariaLabel={`Add ` + addonName + ` to cart`}
        size={"base"}
        type={"primary"}
        onClick={() => {
          addToCart(addonIdentifier);
          setMarketingToolModalOpen(true);
        }}
      />
    </Content>
  );
};

const addToCart = (addonIdentifier: string) => {
  const existingCookie = window.Cookies.get("add_marketing_tool_to_cart");
  if (existingCookie) {
    const decodedCookie = atob(existingCookie);
    const existingFeatures = decodedCookie.split(",");
    if (
      existingFeatures.includes("marketing_suite") ||
      addonIdentifier === "marketing_suite"
    ) {
      return window.Cookies.set(
        "add_marketing_tool_to_cart",
        btoa("marketing_suite"),
        attributes,
      );
    } else if (!existingFeatures.includes(addonIdentifier)) {
      return window.Cookies.set(
        "add_marketing_tool_to_cart",
        btoa(decodedCookie + "," + addonIdentifier),
        attributes,
      );
    }
    window.Cookies.set(
      "add_marketing_tool_to_cart",
      btoa(decodedCookie),
      attributes,
    );
  } else {
    window.Cookies.set(
      "add_marketing_tool_to_cart",
      btoa(addonIdentifier),
      attributes,
    );
  }
};

const attributes = {
  expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7), // 1 week expiration date
  domain: `.${window.location.hostname}`,
};
