import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useAtlantisTheme } from "@jobber/components";
import type { GeoPoint } from "~/utilities/API/graphql";
import styles from "./styles.module.css";
import { MapView } from "./MapView";

interface GoogleMapsProps {
  center: google.maps.LatLngLiteral;
  mRadius: number;
  onAddressChanged: (
    address: string,
    location?: Pick<GeoPoint, "latitude" | "longitude"> | undefined,
  ) => void;
}

export function GoogleMaps({
  center,
  mRadius,
  onAddressChanged,
}: GoogleMapsProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const map = useRef<MapView | null>(null);

  const { theme } = useAtlantisTheme();

  useLayoutEffect(() => {
    if (!map.current) {
      map.current = new MapView(
        divRef.current as HTMLDivElement,
        center,
        mRadius,
        onAddressChanged,
        theme,
      ).mount();
    }
  });

  useEffect(() => {
    map.current?.setCircleRadius(mRadius);
  }, [mRadius]);

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    map.current?.setCenter(center);
  }, [center]);

  return (
    <div
      className={styles.map}
      ref={divRef}
      id="map"
      data-testId="google-map"
    ></div>
  );
}
