import { Segment } from "~/utilities/API/graphql";
import type {
  AllClientsCriteriaInput,
  PastClientsCriteriaInput,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import {
  DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
  DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
} from "../../constants";

export const criteriaDefaults: SegmentCriteriaReducerState = {
  selectedSegmentType: Segment.PAST_CLIENTS,
  allClientsSegmentCriteria: {},
  pastClientsSegmentCriteria: DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
  upcomingClientsSegmentCriteria: DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
  isDirty: false,
};

export type SegmentCriteriaReducerAction =
  | {
      type: "setSelectedSegmentType";
      payload: { segmentType: Segment; dirty?: boolean };
    }
  | {
      type: "setPastClientsSegmentCriteria";
      payload: {
        segmentCriteria: PastClientsCriteriaInput;
        dirty?: boolean;
      };
    }
  | {
      type: "setAllClientsSegmentCriteria";
      payload: {
        segmentCriteria: AllClientsCriteriaInput;
        dirty?: boolean;
      };
    }
  | {
      type: "setUpcomingClientsSegmentCriteria";
      payload: {
        segmentCriteria: UpcomingClientsCriteriaInput;
        dirty?: boolean;
      };
    }
  | {
      type: "resetSegmentCriteria";
    }
  | {
      type: "setDirty";
    }
  | { type: "setNotDirty" };

export interface SegmentCriteriaReducerState {
  selectedSegmentType: Segment;
  allClientsSegmentCriteria?: AllClientsCriteriaInput;
  pastClientsSegmentCriteria: PastClientsCriteriaInput;
  upcomingClientsSegmentCriteria: UpcomingClientsCriteriaInput;
  isDirty: boolean;
}

export function segmentCriteriaReducer(
  value: SegmentCriteriaReducerState,
  action: SegmentCriteriaReducerAction,
): SegmentCriteriaReducerState {
  switch (action.type) {
    case "setSelectedSegmentType":
      return {
        ...value,
        selectedSegmentType: action.payload.segmentType,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "setAllClientsSegmentCriteria":
      return {
        ...value,
        allClientsSegmentCriteria: action.payload.segmentCriteria,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "setPastClientsSegmentCriteria":
      return {
        ...value,
        pastClientsSegmentCriteria: action.payload.segmentCriteria,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "setUpcomingClientsSegmentCriteria":
      return {
        ...value,
        upcomingClientsSegmentCriteria: action.payload.segmentCriteria,
        isDirty: action.payload.dirty || value.isDirty,
      };
    case "resetSegmentCriteria":
      return {
        ...value,
        ...criteriaDefaults,
      };
    case "setDirty":
      return {
        ...value,
        isDirty: true,
      };
    case "setNotDirty":
      return { ...value, isDirty: false };
    default:
      return value;
  }
}
