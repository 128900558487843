import { useHistory } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  URL_PARAM_CAMPAIGNS_TYPE,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/views/CampaignsReviewPage/messages";
import { CampaignStatus, CampaignsFilterFields } from "~/utilities/API/graphql";
import { useMarkDemoCampaignSentMutation } from "jobber/campaigns/hooks/useMarkDemoCampaignSentMutation/useMarkDemoCampaignSentMutation";
import { CampaignReviewErrorState } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCampaignsReviewActions/useCampaignsReviewActions";

export function useHandleMarkDemoCampaignSent(
  campaignId: string,
  status: CampaignStatus | undefined,
) {
  const history = useHistory();
  const formatMessage = useIntl().formatMessage;

  const { markDemoCampaignSent } = useMarkDemoCampaignSentMutation();

  const handleMarkDemoCampaignSent = async (scheduledAt?: Date) => {
    const isCampaignDraftOrScheduled = status
      ? status === CampaignStatus.DRAFT || status === CampaignStatus.SCHEDULED
      : false;
    if (!status || !isCampaignDraftOrScheduled) {
      throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
    }

    await markDemoCampaignSent({
      input: {
        campaignId: campaignId,
        input: { scheduledAt: scheduledAt?.toISOString() },
      },
      onError: () => {
        throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
      },
      onCompleted: data => {
        if (data.markDemoCampaignSent.userErrors.length === 0) {
          history.replace(
            `${CAMPAIGNS_LANDING_PAGE_PATH}?${URL_PARAM_CAMPAIGNS_TYPE}=${CampaignsFilterFields.ONEOFF}`,
          );
          showToast({
            message: formatMessage(messages.sentDemoCampaignToast),
            variation: "success",
          });
        } else {
          throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
        }
      },
    });
  };
  return { handleMarkDemoCampaignSent };
}
