import React, { useEffect, useRef } from "react";

interface XeroDatePickerProps {
  name?: string;
  disabled?: boolean;
  date?: Date;
}

declare global {
  interface JQuery {
    datepicker(options?: { dateFormat?: string }): {
      datepicker(action: "setDate", date: Date): unknown;
    };
    datepicker(action: "destroy"): unknown;
  }

  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    date_format: string;
  }
}

export function XeroDatePicker(props: XeroDatePickerProps) {
  const { name, disabled, date } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    const input = inputRef.current;
    const initialDate = date ? date : new Date();
    $(input)
      .datepicker({ dateFormat: window.date_format })
      .datepicker("setDate", initialDate);

    return () => void $(input).datepicker("destroy");
  }, []);

  return (
    <div className="fieldAffix fieldAffix--inside flexContent u-marginBottomNone">
      <placeholder-field class="placeholderField placeholderField--noMiniLabel u-marginBottomNone">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Grandfathered error: Please fix if touching this code. */}
        <label
          htmlFor=""
          data-label="null"
          className="placeholderField-label is-hidden"
        />
        <input
          ref={inputRef}
          name={name}
          type="text"
          placeholder="Date"
          className="placeholderField-input"
          disabled={disabled}
          style={{ zIndex: 1001 }}
        />
      </placeholder-field>
      <sg-icon icon="calendar" class="fieldAffix-item icon" />
    </div>
  );
}
