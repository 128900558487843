import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "subscriptionAddons.cancelAddonModal.modalTitle",
    defaultMessage: "Cancel {addonName}?",
    description: "Modal title",
  },
  cancel: {
    id: "subscriptionAddons.cancelAddonModal.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button label",
  },
  unsubscribe: {
    id: "subscriptionAddons.cancelAddonModal.unsubscribe",
    defaultMessage: "Unsubscribe",
    description: "Unsubscribe button label",
  },
  resubscribe: {
    id: "subscriptionAddons.cancelAddonModal.resubscribe",
    defaultMessage: "Resubscribe",
    description: "Resubscribe button label",
  },
  cancelAriaLabel: {
    id: "subscriptionAddons.cancelAddonModal.cancelAriaLabel",
    defaultMessage: "Cancel { addonName }",
    description: "Cancel button aria label",
  },
  resubscribeAriaLabel: {
    id: "subscriptionAddons.cancelAddonModal.resubscribeAriaLabel",
    defaultMessage: "Resubscribe to { addonName }",
    description: "Resubscribe button aria label",
  },
  cancellationInformation: {
    id: "subscriptionAddons.cancelAddonModal.cancellationInformation",
    defaultMessage:
      "At the end of your billing period you will lose access to {addonCancellationDetails}, including:",
    description: "Information about what happens when you cancel an addon",
  },
  campaigns: {
    id: "subscriptionAddons.cancelAddonModal.Campaigns",
    defaultMessage: "Campaigns",
    description: "The Campaigns add-on you will lose",
  },
  referrals: {
    id: "subscriptionAddons.cancelAddonModal.Referrals",
    defaultMessage: "Referrals",
    description: "The Referrals add-on you will lose",
  },
  reviews: {
    id: "subscriptionAddons.cancelAddonModal.Reviews",
    defaultMessage: "Reviews",
    description: "The Reviews add-on you will lose",
  },
  marketingSuiteDetails: {
    id: "subscriptionAddons.cancelAddonModal.marketingSuiteDetails",
    defaultMessage: "all add-ons (Reviews, Referrals, and Campaigns)",
    description: "The addons you will lose if you cancel the marketing suite",
  },
  cancellationDisclaimerMessage: {
    id: "subscriptionAddons.cancelAddonModal.cancellationDisclaimerMessage",
    defaultMessage:
      "You will still have access to the addon until the end of this billing period",
    description: "Information about the cancellation policy",
  },
  campaignsFeature1: {
    id: "subscriptionAddons.cancelAddonModal.campaignsFeature1",
    defaultMessage: "creating and sending campaigns",
    description: "A feature that will be lost when cancelling campaigns",
  },
  campaignsFeature2: {
    id: "subscriptionAddons.cancelAddonModal.campaignsFeature2",
    defaultMessage: "viewing and tracking past campaigns",
    description: "A feature that will be lost when cancelling campaigns",
  },
  referralsFeature1: {
    id: "subscriptionAddons.cancelAddonModal.referralsFeature1",
    defaultMessage: "automating incentives for referrals",
    description: "A feature that will be lost when cancelling referrals",
  },
  referralsFeature2: {
    id: "subscriptionAddons.cancelAddonModal.referralsFeature2",
    defaultMessage: "tracking and reporting on referrals",
    description: "A feature that will be lost when cancelling referrals",
  },
  reviewsFeature1: {
    id: "subscriptionAddons.cancelAddonModal.reviewsFeature1",
    defaultMessage: "automatic review requests",
    description: "A feature that will be lost when cancelling reviews",
  },
  reviewsFeature2: {
    id: "subscriptionAddons.cancelAddonModal.reviewsFeature2",
    defaultMessage: "viewing and tracking reviews data",
    description: "A feature that will be lost when cancelling reviews",
  },
  marketingSuiteFeature1: {
    id: "subscriptionAddons.cancelAddonModal.marketingSuiteFeature1",
    defaultMessage: "Reviews: automatically request reviews from customers",
    description: "A feature that will be lost when cancelling marketing suite",
  },
  marketingSuiteFeature2: {
    id: "subscriptionAddons.cancelAddonModal.marketingSuiteFeature2",
    defaultMessage:
      "Referrals: track and reward customers that refer your business",
    description: "A feature that will be lost when cancelling marketing suite",
  },
  marketingSuiteFeature3: {
    id: "subscriptionAddons.cancelAddonModal.marketingSuiteFeature3",
    defaultMessage:
      "Campaigns: create and send campaigns to engage with your customers",
    description: "A feature that will be lost when cancelling marketing suite",
  },
  addonCancelled: {
    id: "subscriptionAddons.cancelAddonModal.addonCancelled",
    defaultMessage: "Cancelled {addonName}",
    description: "Message displayed when an addon is cancelled",
  },
  addonPurchasedIndividually: {
    id: "subscriptionAddons.cancelAddonModal.addonPurchasedIndividually",
    defaultMessage: "All add-ons may be purchased individually.",
    description:
      "Message displayed when the marketing suite addon is cancelled",
  },
  // This is used when the GQL mutation fails, but we don't get the error message back from the userErrors
  genericError: {
    id: "subscriptionAddons.cancelAddonModal.genericError",
    defaultMessage:
      "An unexpected error occurred, please contact us at 1-888-721-1115",
    description: "Generic error message when cancelling an addon fails",
  },
});
