import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import type {
  AutomationsEnabledQuery,
  QuoteFollowUpAutomationRulesQuery,
  QuoteFollowUpClientNotificationQuery,
} from "~/utilities/API/graphql";
import {
  AUTOMATIONS_PAGE_PATH,
  COMMUNICATION_SETTINGS_PATH,
} from "jobber/campaigns/constants";
import {
  AUTOMATIONS_ENABLED_QUERY,
  QUOTE_FOLLOW_UP_AUTOMATION_RULES_QUERY,
  QUOTE_FOLLOW_UP_CLIENT_NOTIFICATION_QUERY,
} from "./useQuoteFollowUpMaxDaysCalculator.graphql";

export function useQuoteFollowUpMaxDaysCalculator() {
  const { data: featureData, loading: featureLoading } =
    useQuery<AutomationsEnabledQuery>(AUTOMATIONS_ENABLED_QUERY);
  const { data: automationData, loading: automationsLoading } =
    useQuery<QuoteFollowUpAutomationRulesQuery>(
      QUOTE_FOLLOW_UP_AUTOMATION_RULES_QUERY,
    );
  const { data: clientNotificationData, loading: clientNotificationsLoading } =
    useQuery<QuoteFollowUpClientNotificationQuery>(
      QUOTE_FOLLOW_UP_CLIENT_NOTIFICATION_QUERY,
    );

  return useMemo(() => {
    if (featureLoading || automationsLoading || clientNotificationsLoading) {
      return {
        maxQuoteFollowUpSentDays: undefined,
        redirectUrl: undefined,
      };
    }

    if (featureData?.feature?.enabled && featureData.feature.available) {
      const activeAutomationDays =
        automationData?.automationRules.nodes
          .filter(
            node =>
              node.trigger.arguments.model_type === "Quote" && node.active,
          )
          .map(node => node.trigger.arguments.days)
          .flat() || [];

      const maxAutomationDays =
        activeAutomationDays.length > 0
          ? Math.max(...activeAutomationDays)
          : undefined;

      return {
        maxQuoteFollowUpSentDays: maxAutomationDays,
        redirectUrl: maxAutomationDays ? AUTOMATIONS_PAGE_PATH : undefined,
      };
    }

    if (
      clientNotificationData?.clientNotification &&
      clientNotificationData?.clientNotification.enabled
    ) {
      const notificationDays =
        clientNotificationData.clientNotification.schedules.nodes
          .filter(
            (node): node is { offset: { value: number } } =>
              node.offset !== undefined,
          )
          .map(node => node.offset.value);

      const maxNotificationDays =
        notificationDays.length > 0 ? Math.max(...notificationDays) : undefined;

      return {
        maxQuoteFollowUpSentDays: maxNotificationDays,
        redirectUrl: COMMUNICATION_SETTINGS_PATH,
      };
    }

    return {
      maxQuoteFollowUpSentDays: undefined,
      redirectUrl: undefined,
    };
  }, [
    featureData,
    featureLoading,
    automationData,
    automationsLoading,
    clientNotificationData,
    clientNotificationsLoading,
  ]);
}
