import { gql } from "@apollo/client";

export const ACCOUNT_TIER_INFO = gql`
  query AccountTierInfo {
    accountPlanInfo {
      planTier
    }
  }
`;

export const USER_LIMITS_PLAN_INFO = gql`
  query UserLimitsPlanInfo {
    users(filter: { status: ACTIVATED }) {
      nodes {
        id
        fullName
        isCurrentUser
      }
      totalCount
    }
  }
`;

export const DEACTIVATE_USERS_FOR_DOWNGRADE = gql`
  mutation DeactivateUsersForDowngrade($ids: [EncodedId!]!) {
    deactivateUsers(ids: $ids) {
      userErrors {
        message
      }
      users {
        id
      }
    }
  }
`;
