import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import type { GroupState } from "./customFieldReducer";
import { CustomField, type CustomFieldValue } from "./CustomField";
import styles from "./CustomFieldGroup.module.css";

interface CustomFieldGroupProps {
  groupState: GroupState;
  showReadOnlyForm: boolean;
  createForm: boolean;
  valueUpdateHandler(configurationId: number, value: CustomFieldValue): void;
}

export function CustomFieldGroup({
  groupState,
  showReadOnlyForm,
  valueUpdateHandler,
  createForm,
}: CustomFieldGroupProps) {
  const sectionCSSToApply = groupState.appTitle ? styles.sectionContainer : "";
  const totalCustomFields = groupState.allConfigurations.length;
  const totalNumberOfReadOnlyFields = determineNumberOfReadOnlyFields();

  return (
    <div className={sectionCSSToApply}>
      {groupState.appTitle && (
        <div className={styles.iconTitleContainer}>
          <div className={styles.appLogoIconContainer}>
            <img
              className={styles.appLogoIcon}
              src={groupState.appIconUrl}
              alt="App logo icon"
            />
          </div>
          <div className={styles.appTitleContainer}>
            <Heading level={6}>{groupState.appTitle}</Heading>
          </div>
        </div>
      )}

      <div className={groupState.appTitle ? styles.customField : ""}>
        {groupState.allConfigurations.map((configurationKey: string) => {
          if (
            showReadOnlyForm ||
            (!showReadOnlyForm &&
              !groupState.byConfiguration[configurationKey].readOnly)
          ) {
            return (
              <CustomField
                key={configurationKey}
                configurationState={
                  groupState.byConfiguration[configurationKey]
                }
                isInAppGroup={!!groupState.appTitle}
                showReadOnlyForm={showReadOnlyForm}
                valueUpdateHandler={valueUpdateHandler}
              />
            );
          }
        })}
      </div>

      {!showReadOnlyForm &&
        groupState.appTitle &&
        !createForm &&
        totalNumberOfReadOnlyFields > 0 && (
          <div className={styles.readOnlyEditingBlurb}>
            <Text variation="subdued" size="small">
              {totalNumberOfReadOnlyFields !== totalCustomFields
                ? `Some ${groupState.appTitle}`
                : groupState.appTitle}{" "}
              custom fields are not editable
            </Text>
          </div>
        )}
    </div>
  );

  function determineNumberOfReadOnlyFields(): number {
    let count = 0;

    groupState.allConfigurations.forEach(configurationKey => {
      if (groupState.byConfiguration[configurationKey].readOnly) count += 1;
    });

    return count;
  }
}
