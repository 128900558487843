import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { showToast } from "@jobber/components/Toast";
import { useMutation } from "@apollo/client";
import {
  ACCOUNT_ADDONS_INFO,
  ACCOUNT_ADDON_CANCEL,
} from "jobber/billing/features/SubscriptionAddons/SubscriptionAddons.graphql";
import type {
  MutationErrors,
  SubscriptionAddonCancelMutation,
  SubscriptionAddonCancelMutationVariables,
} from "~/utilities/API/graphql";
import { PurchaseFormErrors } from "jobber/billing/components/PurchaseFormErrors";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { Intercom } from "utilities/chat";
import { messages } from "./messages";
import styles from "./CancelAddonModal.module.css";

interface CancelAddonModalProps {
  addonName: string;
  addonIdentifier: string;
  addonCode: string;
  isCancelled?: boolean;
  isResubscribeDisabled?: boolean;
  includedAddonSetIdentifiers?: string[];
}

const addonFeaturesMap: { [key: string]: MessageDescriptor[] } = {
  campaigns: [messages.campaignsFeature1, messages.campaignsFeature2],
  reviews: [messages.reviewsFeature1, messages.reviewsFeature2],
  referrals: [messages.referralsFeature1, messages.referralsFeature2],
  marketing_suite: [
    messages.marketingSuiteFeature1,
    messages.marketingSuiteFeature2,
    messages.marketingSuiteFeature3,
  ],
};

const addonCancellationMap: { [key: string]: MessageDescriptor } = {
  campaigns: messages.campaigns,
  reviews: messages.reviews,
  referrals: messages.referrals,
  marketing_suite: messages.marketingSuiteDetails,
};

// eslint-disable-next-line max-statements
export function CancelAddonModal({
  addonIdentifier,
  addonCode,
  addonName,
  includedAddonSetIdentifiers,
  isCancelled,
  isResubscribeDisabled,
}: CancelAddonModalProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

  const { formatMessage } = useIntl();

  const [addonCancel] = useMutation<
    SubscriptionAddonCancelMutation,
    SubscriptionAddonCancelMutationVariables
  >(ACCOUNT_ADDON_CANCEL, {
    refetchQueries: [ACCOUNT_ADDONS_INFO],
  });

  const addonCancellation = formatMessage(
    addonCancellationMap[addonIdentifier],
  );

  const { buttonLabel, buttonAriaLabel } = getButtonText();

  return (
    <>
      {!isResubscribeDisabled && (
        <Button
          size={"small"}
          label={buttonLabel}
          ariaLabel={buttonAriaLabel}
          type={"tertiary"}
          variation={"subtle"}
          onClick={() => setModalOpen(true)}
        />
      )}
      <Modal
        title={formatMessage(messages.modalTitle, { addonName })}
        open={modalOpen}
        onRequestClose={handleCancel}
        primaryAction={{
          label: formatMessage(messages.unsubscribe),
          onClick: submitForm,
          loading: isSubmitting,
          variation: "destructive",
        }}
        secondaryAction={{
          label: formatMessage(messages.cancel),
          disabled: isSubmitting,
          onClick: handleCancel,
        }}
      >
        <Content>
          <PurchaseFormErrors errors={submissionErrors} />
          <Text>
            {formatMessage(messages.cancellationInformation, {
              addonCancellationDetails: addonCancellation,
            })}
          </Text>
          <ul className={styles.featureList}>
            {addonFeaturesMap[addonIdentifier].map((feature, index) => {
              return (
                <li key={`addonFeature-${index}`}>
                  <Text>{formatMessage(feature)}</Text>
                </li>
              );
            })}
          </ul>
          {!!includedAddonSetIdentifiers?.length && (
            <Text>{formatMessage(messages.addonPurchasedIndividually)}</Text>
          )}
          <Text>{formatMessage(messages.cancellationDisclaimerMessage)}</Text>
        </Content>
      </Modal>
    </>
  );

  function handleCancel() {
    setModalOpen(!modalOpen);
    setSubmissionErrors([]);
  }

  async function submitForm() {
    setIsSubmitting(true);
    await handleCancelAddon();
    sendAmplitudeCancelEvent(addonName);
    sendIntercomCancelEvent(addonName);
    setIsSubmitting(false);
  }

  async function handleCancelAddon() {
    const payload = {
      variables: {
        input: {
          addonCode: addonCode,
        },
      },
    };

    try {
      const result = await addonCancel(payload);
      if (result?.data) {
        const userErrors = result.data.subscriptionAddonCancel
          .userErrors as MutationErrors[];

        if (userErrors.length > 0) {
          handleError(userErrors[0].message);
        }

        if (result.data.subscriptionAddonCancel.success) {
          handleSuccess(formatMessage(messages.addonCancelled, { addonName }));
        }
      }
    } catch (addonCancelError) {
      handleError(formatMessage(messages.genericError));
    }
  }

  function handleSuccess(message: string) {
    showToast({
      message: message,
      variation: "success",
    });
    setModalOpen(!modalOpen);
  }

  function handleError(error: string) {
    setSubmissionErrors([error]);
  }

  function getButtonText(): {
    buttonLabel: string;
    buttonAriaLabel: string;
  } {
    if (isCancelled) {
      return {
        buttonLabel: formatMessage(messages.resubscribe),
        buttonAriaLabel: formatMessage(messages.resubscribeAriaLabel, {
          addonName,
        }),
      };
    }
    return {
      buttonLabel: formatMessage(messages.cancel),
      buttonAriaLabel: formatMessage(messages.cancelAriaLabel, { addonName }),
    };
  }
}

function sendIntercomCancelEvent(addonName: string) {
  Intercom.EXECUTE("trackEvent", `Unsubscribed ${addonName} Add-on`);
}

function sendAmplitudeCancelEvent(addonName: string) {
  Amplitude.TRACK_EVENT("CTA Clicked", {
    name: `Unsubscribed ${addonName} Add-on`,
  });
}
