/* eslint-disable max-statements */
/* Disabling this to be able to run the experiment in this file - COBRA KAI */
import React, { useContext, useState } from "react";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { AddonUpsellContext } from "~/jobber/billing/context/AddonUpsellContext/AddonUpsellContext";
import {
  trackCtaClicked,
  trackCtaDismissed,
} from "jobber/billing/utils/trackInteractions";
import { AddonPurchaseModal } from "legacy/jobber/billing/features/AddonPurchaseModal";
import type { PurchaseModalButtonProps } from "legacy/jobber/billing/features/AddonPurchaseModal/AddonPurchaseModal";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { ChatAboutAddonsButton } from "./components/ChatAboutAddonsButton/ChatAboutAddonsButton";
import { RedirectToPricingPageButton } from "./components/RedirectToPricingButton/RedirectToPricingPageButton";
import { messages } from "./messages";
import { AddMarketingToolToCartButton } from "./components/AddMarketingToolToCartButton/AddMarketingToCartButton";
import styles from "./LandingPageCtaButton.module.css";
import { MarketingToolAddToCartModal } from "../MarketingToolAddToCartModal/MarketingToolAddToCartModal";

interface LandingPageCtaButtonProps {
  loading: boolean;
  addonSetIdentifier?: string;
  addonSetName?: string;
  recurlyPublicKey: string;
  redirectToPricingPage: boolean;
  isMobileBilled: boolean;
  salesforceTrackingId: string | null;
  onPurchaseSuccess?: () => void;
  purchaseButtonProps?: PurchaseModalButtonProps;
  isMarketingSource?: boolean;
}

function InternalLandingPageCtaButton(props: LandingPageCtaButtonProps) {
  const {
    loading,
    addonSetIdentifier,
    addonSetName,
    recurlyPublicKey,
    redirectToPricingPage,
    isMobileBilled,
    salesforceTrackingId,
    onPurchaseSuccess,
    purchaseButtonProps,
    isMarketingSource,
  } = props;
  const addonUpSell = useContext(AddonUpsellContext);
  const { formatMessage } = useIntl();
  const [isMarketingToolModalOpen, setMarketingToolModalOpen] = useState(false);
  const isAddToCartExperiment = useFeatureFlag(SplitNames.AddMarketingToCart); // COBRA KAI EXPERIMENT - Ticket to remove it - https://jobber.atlassian.net/browse/JOB-111704

  if (
    isAddToCartExperiment &&
    redirectToPricingPage &&
    addonSetIdentifier &&
    addonSetName
  ) {
    return (
      <div className={styles.addToCartExperiment}>
        <AddMarketingToolToCartButton
          addonName={addonSetName}
          addonIdentifier={addonSetIdentifier}
          setMarketingToolModalOpen={setMarketingToolModalOpen}
        ></AddMarketingToolToCartButton>
        <RedirectToPricingPageButton
          addonSetName={addonSetName}
          isMarketingSource={isMarketingSource}
          isAddMarketingToCartExperiment
        />
        <MarketingToolAddToCartModal
          addonName={addonSetName}
          isOpen={isMarketingToolModalOpen}
          setModalOpen={setMarketingToolModalOpen}
        />
      </div>
    );
  }

  if (redirectToPricingPage) {
    return (
      <RedirectToPricingPageButton
        addonSetName={addonSetName}
        isMarketingSource={isMarketingSource}
      />
    );
  }

  if (addonSetIdentifier === undefined) {
    return (
      <Button
        label={formatMessage(messages.loading)}
        disabled
        loading={true}
        ariaLabel={formatMessage(messages.loading)}
      />
    );
  }

  if (isMobileBilled) {
    return (
      <ChatAboutAddonsButton
        addonSetIdentifier={addonSetIdentifier}
        loading={loading}
      />
    );
  }

  return (
    <AddonPurchaseModal
      recurlyPublicKey={recurlyPublicKey}
      addonSetIdentifier={addonSetIdentifier}
      addonUpsell={addonUpSell}
      buttonProps={{
        buttonSize: "base",
        ...purchaseButtonProps,
      }}
      onOpen={() => {
        trackCtaClicked({
          name: `clicked_add_to_plan_for_${addonSetIdentifier}`,
          salesforceId: salesforceTrackingId,
        });
      }}
      onDismiss={() => {
        trackCtaDismissed({
          name: `${addonSetIdentifier}_purchase_page_purchase_modal`,
          salesforceId: salesforceTrackingId,
        });
      }}
      successAction={() => {
        trackCtaClicked({
          name: `${addonSetIdentifier}_purchase_page_purchase_modal`,
          salesforceId: salesforceTrackingId,
        });

        if (onPurchaseSuccess) {
          onPurchaseSuccess();
        }
      }}
    />
  );
}

export const LandingPageCtaButton = withSplitClient(
  InternalLandingPageCtaButton,
);
