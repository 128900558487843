import React, { useState } from "react";
import { Icon } from "@jobber/components/Icon";
import { ProgressBar } from "@jobber/components/ProgressBar";
// eslint-disable-next-line import/no-deprecated
import { FileUploader_DEPRECATED } from "~/jobber/fileUploader/FileUploader";
import { Thumbnail } from "~/jobber/thumbnail/Thumbnail";

export interface EditableThumbnailProps {
  thumbnail: string;
  canUpload: boolean;
  name: string;
  percentCompleted: number;
  uploading: boolean;
  signingUrl: string;
  s3ThumbnailUrl: string;
  s3Key: string;
  setS3Key(s3Key: string): void;
  setName(url: string): void;
  setThumbnail(thumbnail: string): void;
  setPercentCompleted(percentCompleted: number): void;
  setUploading(uploading: boolean): void;
  onDeleted?(): void;
  onUploaded?(
    fileName: string,
    contentType: string,
    fileSize: number,
    s3Key: string,
    thumbnail: string,
  ): void;
}

// This file will be changing as we are replacing @jobber/react-s3-uploader with dropzone
/** @deprecated this is using the deprecated component FileUploader_DEPRECATED*/
export function EditableThumbnail(props: EditableThumbnailProps) {
  // This should be a useRef and use ref forwarding
  const [inputRef, setInputRef] = useState<HTMLInputElement | undefined>(
    undefined,
  );

  function clearImage() {
    props.setThumbnail("");
    props.setName("");
    props.setS3Key("");
    if (props.onDeleted) {
      props.onDeleted();
    }
  }

  function handleUploadClick() {
    if (inputRef) {
      inputRef.click();
    }
  }

  let thumbnailPlaceholder = <></>;

  if (props.uploading) {
    thumbnailPlaceholder = (
      <div className="u-paddingSmaller">
        <ProgressBar
          currentStep={props.percentCompleted}
          totalSteps={100}
          size={"smaller"}
        />
      </div>
    );
  } else {
    thumbnailPlaceholder = (
      <Thumbnail
        thumbnail={props.thumbnail}
        name={props.name}
        s3ThumbnailUrlPath={props.s3ThumbnailUrl}
        s3Key={props.s3Key}
        setThumbnail={props.setThumbnail}
      />
    );
  }

  return (
    <div
      aria-label="Line Item Image"
      className="flexBlock u-borderRadiusSmall u-bgColorWhite u-border u-borderGreyLighter"
      style={{ height: "4.5rem" }}
    >
      <div className="flexBlock ">
        <div className="flexContent u-marginAuto">{thumbnailPlaceholder}</div>
      </div>
      <div className="flexBlock flexBlock--noGrow flexBlock--noShrink">
        <div className="flexBlock flexVertical u-paddingSmallest">
          <div className={`flexBlock ${props.canUpload ? "" : "u-hidden"}`}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Grandfathered error: Please fix if touching this code. */}
            <label>
              <button
                type="button"
                aria-label="Replace Image"
                onClick={handleUploadClick}
              >
                <Icon name="edit" color="interactive" />
              </button>
              <FileUploader_DEPRECATED
                setInputRef={setInputRef}
                signingUrl={props.signingUrl}
                s3ThumbnailUrl={props.s3ThumbnailUrl}
                setS3Key={props.setS3Key}
                setName={props.setName}
                setThumbnail={props.setThumbnail}
                setPercentCompleted={props.setPercentCompleted}
                setUploading={props.setUploading}
                onUploaded={props.onUploaded}
              />
            </label>
          </div>
          <div className="flexBlock">
            <button
              type="button"
              aria-label="Delete Image"
              onClick={clearImage}
            >
              <Icon name="trash" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
