import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { InputText, type InputTextRef } from "@jobber/components/InputText";
import { InputPassword } from "@jobber/components/InputPassword";
import { Form, type FormRef } from "@jobber/components/Form";
import { useFormState } from "@jobber/hooks/useFormState";
import { Checkbox } from "@jobber/components/Checkbox";
import { Banner } from "@jobber/components/Banner";
import { TimezoneSelector } from "./TimezoneSelector";
import type { SetupFormStepProps } from "./SetupFormStep.d";
import { checkEmail } from "./checkEmail";
import { AliasUsers } from "./AliasUsers";
import classes from "./SetupFormStep.module.css";

// eslint-disable-next-line max-statements
export function SetupFormStep({
  companyName,
  email,
  password,
  timezone,
  userName,
  userPhone,
  aliasUsers,
  showSupplementalTerms,
  termsOfServiceLink,
  distributionChannelName,
  nameOnChange,
  emailOnChange,
  phoneOnChange,
  passwordOnChange,
  companyNameOnChange,
  timezoneOnChange,
  buttonClick,
  timezones,
  submissionErrors,
}: SetupFormStepProps) {
  const [formState, setFormState] = useFormState();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const formRef = useRef() as MutableRefObject<FormRef>;

  const emailRef = React.createRef<InputTextRef>();
  const businessNameRef = React.createRef<InputTextRef>();
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  useEffect(() => {
    // The linter mistakes this for an expression. It was written with optional chaining instead of an if statement to
    // prevent the test branch coverage from going below the required threshold.
    // eslint-disable-next-line no-unused-expressions
    emailRef?.current?.focus();
    // eslint-disable-next-line no-unused-expressions
    businessNameRef?.current?.focus();
  }, []);

  return (
    <Content>
      <Form onStateChange={setFormState} ref={formRef}>
        <Content spacing="large">
          <InputText
            defaultValue={companyName}
            ref={businessNameRef}
            name="company_name"
            placeholder="Business Name"
            onChange={companyNameOnChange}
            validations={{
              required: {
                value: true,
                message: "Business name is required",
              },
            }}
          />

          <InputText
            defaultValue={userName}
            name="user_name"
            placeholder="Name"
            onChange={nameOnChange}
            validations={{
              required: {
                value: true,
                message: "Name is required",
              },
            }}
          />

          <InputText
            defaultValue={userPhone}
            name="user_phone"
            placeholder="Contact Phone"
            onChange={phoneOnChange}
            validations={{
              required: {
                value: true,
                message: "Phone is required",
              },
            }}
          />

          <InputText
            defaultValue={email}
            name="email"
            placeholder="Email"
            onChange={emailOnChange}
            ref={emailRef}
            validations={{
              required: {
                value: true,
                message: "Email address is required",
              },
              pattern: {
                value:
                  /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
                message: "Please enter a valid email",
              },
              validate: validateEmail,
            }}
          />

          <InputPassword
            defaultValue={password}
            name="password"
            placeholder="Password"
            onChange={passwordOnChange}
            validations={{
              required: {
                value: true,
                message: "Password is required",
              },
              minLength: {
                value: 6,
                message: "6 characters minimum, please.",
              },
            }}
          />

          <TimezoneSelector
            onChange={timezoneOnChange}
            timezone={timezone}
            timezones={timezones}
          />

          {aliasUsers.length > 0 && <AliasUsers users={aliasUsers} />}

          <Content>
            <Checkbox
              checked={agreeToTerms}
              label="By checking this box you acknowledge that you have read and agree to:"
              onChange={setAgreeToTerms}
            />
            <ul className={classes.enterpriseList}>
              <li>
                <a
                  href={"https://getjobber.com/terms-of-service/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </li>
              {renderDistributionChannelTerms(distributionChannelName)}
              {renderSupplementalTerms()}
              <li>
                <a
                  href={"https://getjobber.com/privacy-policy"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>

            <>
              {submissionErrors !== "" && (
                <Banner type={"error"}>{submissionErrors}</Banner>
              )}
            </>

            <section className={`u-textRight`}>
              <Button
                label="Set Account Details"
                onClick={handleButtonClick}
                disabled={
                  !formState.isValid || !agreeToTerms || submissionErrors !== ""
                }
                loading={isButtonLoading && submissionErrors === ""}
              />
            </section>
          </Content>
        </Content>
      </Form>
    </Content>
  );

  function renderDistributionChannelTerms(channelName?: string) {
    if (termsOfServiceLink) {
      return (
        <li>
          <a
            href={termsOfServiceLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {channelName} Terms of Service
          </a>
        </li>
      );
    }
  }

  function renderSupplementalTerms() {
    if (showSupplementalTerms) {
      return (
        <li>
          <a
            href={"https://getjobber.com/supplemental-terms-for-franchisees"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Supplemental Terms for Franchisees
          </a>
        </li>
      );
    }
  }

  function handleButtonClick() {
    setIsButtonLoading(true);
    buttonClick();
  }

  async function validateEmail(newEmail: string) {
    const error: string | undefined = await checkEmail(newEmail).then(
      data => data.error,
    );

    if (error === "already_in_use") {
      return "The email entered is already in use.";
    }

    return true;
  }
}
