import { useHistory } from "react-router-dom";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import { useMarkDemoCampaignAutomationActiveMutation } from "jobber/campaigns/hooks/useMarkDemoCampaignAutomationActiveMutation/useMarkDemoCampaignAutomationActiveMutation";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  URL_PARAM_CAMPAIGNS_TYPE,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/views/CampaignsReviewPage/messages";
import { CampaignStatus, CampaignsFilterFields } from "~/utilities/API/graphql";
import { CampaignReviewErrorState } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCampaignsReviewActions/useCampaignsReviewActions";

export function useHandleMarkDemoCampaignAutomationActive(
  campaignId: string,
  status: CampaignStatus | undefined,
) {
  const history = useHistory();
  const formatMessage = useIntl().formatMessage;

  const { markDemoCampaignAutomationActive } =
    useMarkDemoCampaignAutomationActiveMutation();

  const handleMarkDemoCampaignAutomationActive = async () => {
    if (
      !status ||
      (status !== CampaignStatus.DRAFT &&
        status !== CampaignStatus.AUTOMATIONINACTIVE)
    ) {
      throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
    }

    await markDemoCampaignAutomationActive({
      input: {
        campaignId: campaignId,
      },
      onError: () => {
        throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
      },
      onCompleted: data => {
        if (data.markDemoCampaignAutomationActive.userErrors.length === 0) {
          history.replace(
            `${CAMPAIGNS_LANDING_PAGE_PATH}?${URL_PARAM_CAMPAIGNS_TYPE}=${CampaignsFilterFields.AUTOMATED}`,
          );
          showToast({
            message: formatMessage(
              messages.activatedDemoAutomatedCampaignToast,
            ),
            variation: "success",
          });
        } else {
          throw new Error(CampaignReviewErrorState.GENERIC_ERROR);
        }
      },
    });
  };
  return { handleMarkDemoCampaignAutomationActive };
}
