import React, { useEffect, useState } from "react";
import type { InternalRefetchQueriesInclude } from "@apollo/client";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { omit } from "lodash";
import { useIntl } from "react-intl";
import { Form } from "jobber/workItems/components/Form";
import { DEFAULT_DURATION } from "jobber/workItems/components/constants";
import type { WorkItem } from "jobber/workItems/types";
import { withIntlProvider } from "@translations/withIntlProvider";
import type { ProductsAndServicesCategory } from "~/utilities/API/graphql";
import type {
  FileAttachmentSaveAttributes,
  ProductOrServiceSaveAttributes,
  SaveResult,
} from "./hooks";
import { useProductOrServiceSave, useProductsAndServicesDelete } from "./hooks";
import { messages } from "./messages";

interface SaveButton {
  label: string;
  saveOverride?(input: ProductOrServiceSaveAttributes): Promise<SaveResult>;
}
interface DeleteButton {
  deleteOverride?(id: string): Promise<SaveResult>;
  label?: string;
  hidden?: boolean;
  confirmationMessage?: string;
}
export interface WorkItemModalProps {
  workItem: WorkItem;
  currencySymbol: string;
  modalOpen: boolean;
  modalTitle: string;
  onSelfServeBookingsPage?: boolean;
  quoteMarginsEnabled: boolean;
  advancedQuotingEnabled: boolean;
  selfServeBookingsEnabled: boolean;
  closeModal(): void;
  onSaved?(updatedLineItem: WorkItem): void;
  onDelete?(): void;
  saveButton?: SaveButton;
  deleteButton?: DeleteButton;
  refetchQueries?: InternalRefetchQueriesInclude;
  customFieldsSection?: React.ReactNode;
}

// eslint-disable-next-line max-statements
export function WorkItemModal({
  workItem,
  currencySymbol,
  quoteMarginsEnabled,
  selfServeBookingsEnabled,
  advancedQuotingEnabled,
  modalTitle,
  modalOpen,
  onSelfServeBookingsPage,
  closeModal: externalCloseModal,
  onSaved,
  onDelete,
  saveButton,
  deleteButton,
  refetchQueries,
  customFieldsSection,
}: WorkItemModalProps) {
  const { formatMessage } = useIntl();
  const { saveProductOrService } = useProductOrServiceSave(refetchQueries);

  const { productsAndServicesDelete } =
    useProductsAndServicesDelete(refetchQueries);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");
  const [saving, setSaving] = useState(false);

  const [workItemCopy, setWorkItemCopy] = useState(workItem);

  useEffect(() => {
    // The work item may change after first render (i.e. when being filled in by a partially-entered quote line item)
    // This updates the Modal/Form when that property changes due to these outside events
    setWorkItemCopy(workItem);
  }, [workItem]);

  const modalButtons = buildModalButtons(workItem.id);

  return (
    <>
      <Modal
        title={modalTitle}
        open={modalOpen}
        onRequestClose={setModalClosed}
        {...modalButtons}
      >
        <Content>
          {mutationErrorMessage && (
            <Banner type="error" onDismiss={onErrorBannerDismiss}>
              {mutationErrorMessage}
            </Banner>
          )}
          <Form
            workItem={workItemCopy}
            onChange={setWorkItemCopy}
            currencySymbol={currencySymbol}
            onSelfServeBookingsPage={onSelfServeBookingsPage}
            advancedQuotingEnabled={advancedQuotingEnabled}
            quoteMarginsEnabled={quoteMarginsEnabled}
            selfServeBookingsEnabled={selfServeBookingsEnabled}
          />
          {customFieldsSection}
        </Content>
      </Modal>

      <ConfirmationModal
        variation="destructive"
        title={
          deleteButton?.label
            ? `${deleteButton.label} ${workItemCopy.name}`
            : `${formatMessage(messages.deleteWorkItemConfirmationTitle, {
                workItemName: workItemCopy.name,
              })}`
        }
        message={
          deleteButton?.confirmationMessage ??
          `${formatMessage(messages.deleteWorkItemConfirmationMessage, {
            workItemName: workItemCopy.name,
          })}${
            selfServeBookingsEnabled && workItem.onlineBookingsEnabled
              ? formatMessage(
                  messages.deleteWorkItemConfirmationOnlineBookingCondition,
                )
              : ""
          }.`
        }
        confirmLabel={
          deleteButton?.label ||
          formatMessage(messages.deleteWorkItemConfirmationLabel)
        }
        open={showDeleteConfirmModal}
        onConfirm={handleDelete}
        onCancel={closeConfirm}
        onRequestClose={closeConfirm}
        size={"small"}
      />
    </>
  );

  async function callSave(): Promise<string[]> {
    const attributes = workItemToMutationAttributes(
      filterProperties(workItem, workItemCopy),
      quoteMarginsEnabled,
      advancedQuotingEnabled,
      selfServeBookingsEnabled,
    );

    if (!attributesValid(attributes)) {
      return [formatMessage(messages.errorFixValidationsBeforeSaving)];
    }

    if (saveButton?.saveOverride) {
      return (await saveButton.saveOverride(attributes)).errors;
    }
    return (await saveProductOrService(attributes)).errors;
  }

  async function handleSave() {
    try {
      setSaving(true);

      const errors = await callSave();

      if (errors.length > 0) {
        handleMutationError(new Error(errors[0]));
      } else {
        if (onSaved) {
          onSaved(workItemCopy);
        }
        setModalClosed();
      }
    } catch (error) {
      handleMutationError(error as Error);
    } finally {
      setSaving(false);
    }
  }

  function setModalClosed() {
    externalCloseModal();
    setWorkItemCopy(workItem);
    onErrorBannerDismiss();
  }

  function onErrorBannerDismiss() {
    setMutationErrorMessage("");
  }

  function handleMutationError(mutationError: Error | undefined) {
    setMutationErrorMessage(mutationError?.message || getGenericError());
  }
  function closeConfirm() {
    setShowDeleteConfirmModal(false);
  }
  //currently id is typed as number in the future it will be string
  function buildModalButtons(id: string | number | undefined) {
    if (id) {
      return {
        primaryAction: {
          label:
            saveButton?.label || formatMessage(messages.updateWorkItemLabel),
          ariaLabel: formatMessage(messages.updateWorkItemAriaLabel),
          loading: saving,
          disabled: saving,
          onClick: handleSave,
        },
        secondaryAction: {
          label: formatMessage(messages.workItemModalCancelLabel),
          ariaLabel: formatMessage(messages.workItemCancelAriaLabel),
          disabled: saving,
          onClick: setModalClosed,
        },
        tertiaryAction: deleteButton?.hidden
          ? undefined
          : {
              label:
                deleteButton?.label ||
                formatMessage(messages.workItemDeleteLabel),
              ariaLabel: formatMessage(messages.workItemDeleteAriaLabel),
              onClick:
                !deleteButton || deleteButton.confirmationMessage
                  ? confirmDelete
                  : handleDelete,
            },
      };
    }

    return {
      primaryAction: {
        label: saveButton?.label || formatMessage(messages.workItemCreateLabel),
        ariaLabel: formatMessage(messages.workItemCreateAriaLabel),
        loading: saving,
        disabled: saving,
        onClick: handleSave,
      },
      secondaryAction: {
        label: formatMessage(messages.workItemModalCancelLabel),
        ariaLabel: formatMessage(messages.workItemCancelAriaLabel),
        disabled: saving,
        onClick: setModalClosed,
      },
    };
  }

  async function callDelete(): Promise<string[]> {
    if (!workItemCopy.id) {
      return [formatMessage(messages.errorCannotDeleteUnsavedWorkItem)];
    }

    if (deleteButton?.deleteOverride) {
      return (await deleteButton.deleteOverride(workItemCopy.id)).errors;
    } else {
      return (await productsAndServicesDelete([workItemCopy.id])).errors;
    }
  }

  async function handleDelete() {
    setSaving(true);

    try {
      const errors = await callDelete();

      if (errors.length > 0) {
        handleMutationError(new Error(errors[0]));
      } else {
        if (onDelete) {
          onDelete();
        }
        setModalClosed();
      }
    } catch (error) {
      handleMutationError(error as Error);
    } finally {
      setSaving(false);
    }
  }

  async function confirmDelete() {
    setShowDeleteConfirmModal(true);
  }

  function getGenericError() {
    return workItemCopy.id
      ? formatMessage(messages.errorCouldNotUpdateWorkItem)
      : formatMessage(messages.errorCouldNotCreateWorkItem);
  }
}

export const WorkItemModalWithIntl = withIntlProvider(WorkItemModal);

// This duplicates the validation on the quantity inputs, but since the form
// isn't a Form component we can't use those validations to block saving.
function attributesValid(attributes: ProductOrServiceSaveAttributes): boolean {
  if (attributes.quantityRange?.quantityEnabled) {
    if (typeof attributes.quantityRange.minQuantity !== "number") return false;
    if (typeof attributes.quantityRange.maxQuantity !== "number") return false;
    if (attributes.quantityRange.minQuantity < 0) return false;
    if (attributes.quantityRange.maxQuantity < 0) return false;
    if (
      attributes.quantityRange.minQuantity >=
      attributes.quantityRange.maxQuantity
    ) {
      return false;
    }
  }
  return true;
}

export function workItemToMutationAttributes(
  workItem: WorkItem,
  quoteMarginsEnabled: boolean,
  advancedQuotingEnabled: boolean,
  selfServeBookingsEnabled: boolean,
): ProductOrServiceSaveAttributes {
  const unitCostToNumber = parseFloat(`${workItem.defaultUnitCost}`);
  const internalUnitCostToNumber = parseFloat(`${workItem.internalUnitCost}`);
  const markupToNumber = parseFloat(`${workItem.markup}`);
  const fileAttachments = setFileAttachmentForMutation(workItem);
  if (workItem.onlineBookingsEnabled && !workItem.durationMinutes) {
    workItem.durationMinutes = DEFAULT_DURATION;
  }
  const includeDuration = workItem.onlineBookingsEnabled;

  return {
    id: workItem.id,
    name: workItem.name,
    description: workItem.description || "",
    category: workItem.category as ProductsAndServicesCategory,
    defaultUnitCost: unitCostToNumber,
    taxable: !!workItem.taxable,
    visible: workItem.visible,

    ...(quoteMarginsEnabled && {
      internalUnitCost: internalUnitCostToNumber,
      markup: markupToNumber,
    }),
    ...(advancedQuotingEnabled &&
      fileAttachments && {
        image: fileAttachments,
      }),
    ...(selfServeBookingsEnabled && {
      onlineBookingsEnabled: workItem.onlineBookingsEnabled,
      ...(includeDuration && {
        durationMinutes: workItem.durationMinutes,
        bookableType: workItem.bookableType,
        quantityRange: workItem.quantityRange
          ? omit(workItem.quantityRange, "__typename")
          : null,
      }),
    }),
  };
}

export function filterProperties(
  originalItem: WorkItem,
  formValues: WorkItem,
): WorkItem {
  if (formValues.quantityRange?.quantityEnabled) {
    return formValues;
  }

  // This prevents saving the hidden values in the form
  // while maintaining the original values if set
  if (originalItem.quantityRange) {
    return {
      ...formValues,
      quantityRange: {
        ...originalItem.quantityRange,
        quantityEnabled: false,
      },
    };
  }

  // This prevents creating creating a new record when the sp
  // opened and closed the section for an item that has never had a quantity range
  return {
    ...formValues,
    quantityRange: undefined,
  };
}

function setFileAttachmentForMutation(
  workItem: WorkItem,
): FileAttachmentSaveAttributes | undefined {
  if (workItem.fileAttachment) {
    return {
      fileName: workItem.fileAttachment.fileName,
      contentType: workItem.fileAttachment.contentType,
      fileSize: workItem.fileAttachment.fileSize,
      fileableType: "WorkItem",
      s3Key: workItem.fileAttachment.s3Key,
    };
  } else {
    return undefined;
  }
}
