import React, { useEffect } from "react";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Grid } from "@jobber/components/Grid";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "~/utilities/analytics/ANALYTICS_EVENTS";
import { ANALYTICS_PROPERTIES } from "jobber/marketplace/ANALYTICS_EVENTS";
import { IntegrationRequestModal } from "./IntegrationRequestModal";

export interface IntegrationRequestProps {
  name: string;
  description: string;
  modalOpen: boolean;
  loading: boolean;
  setModalOpen(modalOpen: boolean): void;
  onChangeName(name: string): void;
  onChangeDescription(name: string): void;
  onAppRequestCreate(): void;
  publicFacing: boolean;
}

export function IntegrationRequest({
  name,
  description,
  modalOpen,
  loading,
  setModalOpen,
  onChangeName,
  onChangeDescription,
  onAppRequestCreate,
  publicFacing,
}: IntegrationRequestProps) {
  /* istanbul ignore next -- @preserve */
  function openModal() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedDialog, {
      name: ANALYTICS_PROPERTIES.requestAnAppModalSource,
    });
    setModalOpen(true);
  }

  const [ref, { width }] = useResizeObserver<HTMLDivElement>();

  function getSize() {
    return width && width < Breakpoints.base ? "start" : "center";
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const setModalOpenFromParams = queryParameters.get(
      "open_integration_request",
    );

    if (setModalOpenFromParams === "true") {
      openModal();
    }
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
      }}
      ref={ref}
    >
      <IntegrationRequestModal
        name={name}
        description={description}
        modalOpen={modalOpen}
        loading={loading}
        setModalOpen={setModalOpen}
        onChangeName={onChangeName}
        onChangeDescription={onChangeDescription}
        onAppRequestCreate={onAppRequestCreate}
      />

      <Grid gap={false} alignItems={getSize()}>
        <Grid.Cell
          size={{
            xs: 2,
            md: 1,
          }}
        >
          <Icon name="knot" size="base" color="green" />
        </Grid.Cell>
        <Grid.Cell
          size={{
            xs: 10,
            md: 11,
          }}
        >
          <Grid alignItems={getSize()}>
            <Grid.Cell
              size={{
                xs: 12,
                md: 9,
              }}
            >
              <div style={{ textAlign: "left" }}>
                <Text>
                  <b>Looking for something?</b> Request a new app that would
                  make your work easier.
                </Text>
              </div>
            </Grid.Cell>
            <Grid.Cell
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <div style={{ textAlign: "left" }}>
                <Button
                  label="Request an App"
                  type="secondary"
                  {...(publicFacing
                    ? { url: `/marketplace?open_integration_request=true` }
                    : { onClick: openModal })}
                />
              </div>
            </Grid.Cell>
          </Grid>
        </Grid.Cell>
      </Grid>
    </div>
  );
}
