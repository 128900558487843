import React, { useMemo, useState } from "react";
import { Text } from "@jobber/components/Text";
import { useCreateBridgeMethod } from "jobber/hooks/useCreateBridgeMethod";
import { useUtcTimeToDeviceTime } from "./useUtcTimeToDeviceTime";
import { getArrivalWindowStartTime } from "./getArrivalWindowStartTime";

interface ArrivalWindowRangeProps {
  duration: number;
  centered: boolean;
  initialStartTime: {
    time: string | null;
    offsetInSeconds: number;
  };
}

const formatTime = (date: Date): string => {
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: !window.is_24_hour_format,
  });
};

export const ArrivalWindowRange = ({
  centered,
  duration,
  initialStartTime,
}: ArrivalWindowRangeProps) => {
  const timezoneAdjustedInitialStartTime = useUtcTimeToDeviceTime(
    initialStartTime.time,
    initialStartTime.offsetInSeconds,
  );
  const [startTime, setStartTime] = useState<Date | undefined>(
    timezoneAdjustedInitialStartTime,
  );
  useCreateBridgeMethod("setArrivalWindowStartTime", setStartTime);

  const arrivalWindowStartTime = getArrivalWindowStartTime({
    centered,
    duration,
    jobStartTime: startTime,
  });

  const displayText = useMemo(() => {
    if (!arrivalWindowStartTime) {
      return null;
    }

    const endTime = new Date();
    endTime.setTime(arrivalWindowStartTime.getTime() + duration * 60 * 1000);

    return `Arriving between ${formatTime(
      arrivalWindowStartTime,
    )} – ${formatTime(endTime)}`;
  }, [duration, arrivalWindowStartTime]);

  return displayText ? <Text size={"small"}>{displayText}</Text> : null;
};
