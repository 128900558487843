import React from "react";
import { gql } from "@apollo/client";
import { useIntl } from "react-intl";
import type { QboLastSyncDataFragment } from "~/utilities/API/graphql";
import { InsightCard } from "jobber/dashboard/components/InsightCard";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { messages } from "./messages";

export const QBOLastSyncData = gql`
  fragment QBOLastSyncData on QBOLastSync {
    daysSince
  }
`;
interface QBOLastSyncInsightProps {
  qboLastSync?: QboLastSyncDataFragment;
  trackEvents: boolean;
}

export function QBOLastSyncInsight({
  qboLastSync,
  trackEvents,
}: QBOLastSyncInsightProps) {
  const [qboSyncSettingsPath] = useUrls("qboSyncSettingsPath");
  const lastSyncedDays = qboLastSync?.daysSince || 0;
  const hideIfSyncedRecently = lastSyncedDays <= 14;

  const { formatMessage } = useIntl();

  return (
    <InsightCard
      title={formatMessage(messages.quickBooksSync)}
      description={formatMessage(messages.quickBooksSyncDescription, {
        lastSyncedDays,
      })}
      actionLabel={formatMessage(messages.openSync)}
      actionUrl={qboSyncSettingsPath}
      hideEmptyCard={hideIfSyncedRecently}
      trackEvents={trackEvents}
    />
  );
}
