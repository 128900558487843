import React from "react";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { useIntl } from "react-intl";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { messages } from "../../messages";

export interface AppDisconnectModalProps {
  appName: string;
  openDisconnectedModal: boolean;
  openManageCustomFieldsModal: boolean;
  onConfirmDisconnect(): void;
  onCancelDisconnect(): void;
  onManageCustomFields(): void;
  onCancelManageCustomFields(): void;
}

export function AppDisconnectModal({
  appName,
  onConfirmDisconnect,
  onCancelDisconnect,
  onManageCustomFields,
  onCancelManageCustomFields,
  openDisconnectedModal,
  openManageCustomFieldsModal,
}: AppDisconnectModalProps) {
  const { formatMessage } = useIntl();

  const {
    appDisconnectModalConfirmLabel,
    appDisconnectModalMessage,
    appDisconnectModalTitle,
    appDisconnectedModalManageCustomFieldsLabel,
    appDisconnectedModalManageCustomFieldsDisconnectedMessage,
    appDisconnectedModalManageCustomFieldsInfoMessage,
    appDisconnectedModalManageCustomFieldsTitle,
    appDisconnectedModalManageCustomFieldsCancelLabel,
    appDisconnectedModalCustomFields,
  } = messages;

  const { confirmLabel, confirmMessage, confirmTitle } = {
    confirmLabel: formatMessage(appDisconnectModalConfirmLabel),
    confirmMessage: formatMessage(appDisconnectModalMessage, { appName }),
    confirmTitle: formatMessage(appDisconnectModalTitle, { appName }),
  };

  const {
    manageLabel,
    manageCancelLabel,
    disconnectedMessage,
    infoMessage,
    customFieldsTitle,
    manageTitle,
  } = {
    manageLabel: formatMessage(appDisconnectedModalManageCustomFieldsLabel),
    manageCancelLabel: formatMessage(
      appDisconnectedModalManageCustomFieldsCancelLabel,
    ),
    disconnectedMessage: formatMessage(
      appDisconnectedModalManageCustomFieldsDisconnectedMessage,
      {
        appName,
      },
    ),
    infoMessage: formatMessage(
      appDisconnectedModalManageCustomFieldsInfoMessage,
    ),
    manageTitle: formatMessage(appDisconnectedModalManageCustomFieldsTitle, {
      appName,
    }),
    customFieldsTitle: formatMessage(appDisconnectedModalCustomFields),
  };

  function manageCustomFieldsContent() {
    return (
      <Content>
        <Text>
          {disconnectedMessage}
          <br />
          <br />
          <a
            href="https://help.getjobber.com/hc/en-us/articles/115009735928-Custom-Fields"
            target="blank"
            rel="noopener noreferrer"
          >
            {customFieldsTitle}
          </a>{" "}
          {infoMessage}
        </Text>
      </Content>
    );
  }

  return (
    <>
      <ConfirmationModal
        variation="destructive"
        title={confirmTitle}
        message={confirmMessage}
        confirmLabel={confirmLabel}
        open={openDisconnectedModal}
        onConfirm={onConfirmDisconnect}
        onRequestClose={onCancelDisconnect}
      />

      <ConfirmationModal
        variation="work"
        title={manageTitle}
        confirmLabel={manageLabel}
        cancelLabel={manageCancelLabel}
        open={openManageCustomFieldsModal}
        onConfirm={onManageCustomFields}
        onRequestClose={onCancelManageCustomFields}
      >
        {manageCustomFieldsContent()}
      </ConfirmationModal>
    </>
  );
}
