import * as React from "react";
import type {
  CommonSignaturePadProps,
  SignaturePadWebComponent,
} from "components/SignaturePad/SignaturePadTypes";
import { useSignaturePadCallback } from "components/SignaturePad/useSignaturePadCallback";
import syncListenerWithElement from "jobber/payments_sca/utils/syncListenerWithElement";

export function DrawSignaturePad(props: CommonSignaturePadProps) {
  const { onSignatureDataUrlChange: onChange, Component = "signature-pad" } =
    props;
  const [ref, update, reset] = useSignaturePadCallback({ onChange });
  const [startDrawing, updateWithoutStopping, stopDrawing] =
    useUpdateOnDrawingEndHandler(update);

  const [onNewRefUpdateReset] = useResetHandler(reset);
  const onNewRef = (node: SignaturePadWebComponent | undefined | null) => {
    onNewRefUpdateReset(node);
    ref.current = node;
  };

  const padProps = {
    ref: onNewRef,

    onMouseDown: startDrawing,
    onMouseUp: stopDrawing,
    onMouseOut: updateWithoutStopping,

    onTouchStart: startDrawing,
    onTouchEnd: stopDrawing,
    onTouchCancel: updateWithoutStopping,
  };

  return <Component {...padProps} />;
}

function useUpdateOnDrawingEndHandler(updateHandler: () => void) {
  const [isDrawing, setDrawing] = React.useState(false);
  const startDrawing = React.useCallback(() => {
    setDrawing(true);
  }, [setDrawing]);
  const updater = React.useCallback(
    ({ endDraw = false }: { endDraw?: boolean } = {}) =>
      () => {
        if (!isDrawing) {
          return;
        }
        updateHandler();
        if (endDraw) {
          setDrawing(false);
        }
      },
    [isDrawing, updateHandler, setDrawing],
  );
  const updateWithoutStopping = React.useCallback(updater(), [updater]);
  const stopDrawing = React.useCallback(updater({ endDraw: true }), [updater]);

  return [startDrawing, updateWithoutStopping, stopDrawing];
}

function useResetHandler(resetHandler: () => void) {
  const listenerUpdater = syncListenerWithElement({ eventName: "click" });
  const previousClearButton = React.useRef<Element | null>();
  const previousResetHandler = React.useRef(resetHandler);

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const onNewRef = React.useCallback(
    (newNode: Element | undefined | null) => {
      const clearButton =
        newNode && newNode.querySelector(".signaturePad-clear .button");

      listenerUpdater({
        previousElement: previousClearButton.current,
        previousListener: previousResetHandler.current,
        newElement: clearButton,
        newListener: resetHandler,
      });

      previousClearButton.current = clearButton;
    },
    [resetHandler, previousClearButton, previousResetHandler],
  );

  React.useEffect(() => {
    const clearButton = previousClearButton.current;

    listenerUpdater({
      previousElement: clearButton,
      previousListener: previousResetHandler.current,
      newElement: clearButton,
      newListener: resetHandler,
    });

    previousResetHandler.current = resetHandler;
  }, [resetHandler]);

  return [onNewRef];
}
