import React from "react";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { RequestCardOnFileButton } from "jobber/payments_sca/components/RequestCardOnFileButton/RequestCardOnFileButton";
import styles from "./PaymentMethodsEmptyState.module.css";

interface PaymentMethodsEmptyStateProps {
  requestCardOnFileEmailUrl: string;
  requestCardOnFileSmsUrl: string;
}

export const PaymentMethodsEmptyState = (
  props: PaymentMethodsEmptyStateProps,
) => {
  const { requestCardOnFileEmailUrl, requestCardOnFileSmsUrl } = props;

  return (
    <div className={styles.paddingSmall} data-testid="paymentmethodsemptystate">
      <div
        className={`${styles.paragraph} ${styles.marginBottomSmall}`}
        aria-label="Empty Payment Methods Message"
      >
        <Text>
          <em>
            There&rsquo;s no payment method saved on file for this client. They
            can manage their digital wallet in client hub.
          </em>
        </Text>
      </div>
      <div className={styles.marginBottomSmall}>
        <Heading level={5}>Request payment method on file</Heading>
      </div>
      <div className={styles.flexAndWrap}>
        <div
          className={styles.paddingRightSmall}
          data-testid="emailRequestButton"
        >
          <RequestCardOnFileButton
            label={"Send email request"}
            requestCardOnFileEmailUrl={requestCardOnFileEmailUrl}
          />
        </div>
        <div data-testid="smsRequestButton">
          <RequestCardOnFileButton
            label={"Send SMS request"}
            requestCardOnFileSmsUrl={requestCardOnFileSmsUrl}
          />
        </div>
      </div>
    </div>
  );
};
